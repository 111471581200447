//资源中心
const Resources = () => import ("../views/resources/resource_center.vue");
//京银互动一带一路共读工程
const jingyin = () =>import ("../views/resources/jingyin/jingyin.vue");
//名校联盟双师课堂
const preview = () =>import ("../views/resources/preview/preview.vue");
export default [{
    path: "/resources",
    name: "resources",
    component: Resources,
    meta: {
        title: "资源中心",
        keepAlive: false
    }
},{
    path: "/jingyin",
    name: "jingyin",
    component: jingyin,
    meta: {
        title: "资源中心",
        keepAlive: false
    }
},{
    path: "/preview",
    name: "preview",
    component: preview,
    meta: {
        title: "资源中心",
        keepAlive: false
    }
}];