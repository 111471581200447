import {
    get,
    put,
    patch,
    post,
    fetch,
    del,
    Delete
} from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
const Course = {
    //查看课程列表
    getCourseList(data) {
        return get("/course/teach/course/list", data);
    },
    //课程管理-获取课节列表
    getLessonList(data) {
        return fetch("/course/teach/lesson/getLesson/", data);
    },
    //课程管理-获取课节列表(带作业)
    getLessonNewList(data) {
        return fetch("/course/teach/lesson/getLessonNew/", data);
    },
    //创建课节
    creatLesson(data) {
        return post("/course/teach/lesson/create", data);
    },

    //查看课程列表 (管理员项目课管理出现前使用 -- 现暂停使用)
    // courseLists(data) {
    //     return get("/course/teach/course/listForExpert", data);
    // },

    // //查看课程列表
    courseLists(data) {
        return get("/course/teach/course/listForExpert2", data);
    },
    //创建课程
    creatCourse(data) {
        return post("/course/teach/course/create", data);
    },
    //课程详情
    findCourse(data) {
        return fetch("/course/teach/course/findCourseById/", data);
    },
    // 查询课程统计数据
    getCourseStudyInfo(data) {
        return fetch("/course/teach/course/courseStudyInfo/", data);
    },
    //修改课程
    editCourse(data) {
        return put("/course/teach/course/updateCourse", data);
    },
    // 课程学科列表
    SubjectList(data) {
        return get("/course/query/subject/list", data);
    },
    // 课程类型列表
    TypeList(data) {
        return get("/course/query/type/list", data);
    },
    // 授课老师列表或助教列表
    TeacherList(data) {
        return post("/course/query/info/teachers", data);
    },
    // 文件或图片显示
    Download(data) {
        return get("/fu/front/upload/download", data);
    },
    LessonTeacherList(data) {
        return get("/course/query/info/teachers", data);
    },
    //查询用户组信息
    GroupInfo(data) {
        return fetch("/course/item/groupInfo/", data);
    },
    //查询项目信息
    ListBySecondId(data) {
        return fetch("/course/item/listBySecondId/", data);
    },
    //获取后台课程模板列表
    baseCourseList(data) {
        return get("/course/sys/baseCourseList", data);
    },
    //获取后台教材模板列表
    baseMaterialList(data) {
        return get("/course/sys/baseMaterialList", data);
    },
    // 获取二级项目列表（共享课程）
    allProjectList(data) {
        return get("/course/item/allProjectList", data);
    },
    // 查询 共享课程
    getShareCourseInfo(data) {
        return get("/course//teach/course/getShareCourseInfo", data);
    },
    // 提交分享课程
    shareCourse(data) {
        return post("/course/teach/course/shareCourse", data);
    },
    //查看课节列表（给专家和助教）
    getExpertLesssonList(data) {
        return get("/course/teach/lesson/expertGetLesson", data);
    },
    //获取课节信息
    getLesson(data) {
        return fetch("/course/teach/lesson/getLessonById/", data);
    },
    //编辑课节信息
    editLesson(data) {
        return put("/course/teach/lesson/updateLesson", data);
    },
    //查询用户统计信息(非专家、非老师)
    personCount(data) {
        return get("/course/statistics/count/course", data);
    },
    // 查询用户统计信息(专家)
    personCountExpert(data) {
        return get("/score/projectHome/getWholeStudyInfo", data);
    },
    // 查询用户统计信息(参训教师)
    personCountTea(data) {
        return get("/score/projectHome/getUserStatisticTitleInfo", data);
    },
    //查询当天课程（领导）
    getTodayCourse(data) {
        return get("/course/teach/lesson/queryNowDayCourses", data);
    },
    //课程管理-查询一级能力课程
    queryAbilityCourse(data) {
        return get("/course/teach/course/queryAbilityCourse", data);
    },
    //获取异步课视频课节信息
    getAsnycLive(data) {
        return fetch("/course/teach/lesson/getLessonById/", data);
    },
    //获取异步课的视频地址
    getLessonVideoUrl(data) {
        return fetch("/course/teach/lesson/getLessonVideoUrl/", data);
    },
    //课程详情 -- 获取下一学习任务
    getNextLesson(data) {
        return fetch("/course/teach/lesson/getNextLesson/", data);
    },
    //课程详情 -- 课程详情
    getCourseInfo(data) {
        return fetch("/course/teach/course/courseInfo/", data);
    },
    //课程详情 -- 课程详情
    check_first_login(data) {
        return get("/auth/sign/check_first_login", data);
    },
    // 教学数据
    listLessonCount(data) {
        return get("/course/teach/lessonCount/ListLessonCount", data);
    },
    // 课程详情数据统计
    getCourseCount(data) {
        return get("/course/teach/lessonCount/getCourseCount", data);
    },
    // 删除课程
    courseDel(data) {
        return del("/course/teach/course/delete/", data);
    },
    // 删除课节
    classPeriodDel(data) {
        return del("/course/teach/lesson/delete/", data);
    },
    // 新建讨论
    getcreate(data) {
        return post("/course/teach/comment/create", data);
    },
    // 讨论列表
    getCommentList(data) {
        return fetch("/course/teach/comment/list/", data);
    },
    // 删除讨论
    commentDel(data) {
        return del("/course/teach/comment/delete/", data);
    },
    // 异步课统计用户上课时间
    countTime(data) {
        return put("/course/teach/lessonCount/updateCourseCountAsyn", data);
    },
    // 异步课统计上课时长 本次观看时间戳
    watchStamp(data) {
        return put("/course/teach/lessonCount/startWatch", data);
    },
    // // 删除课节
    // classPeriodDel(data) {
    //     return del("/course/teach/comment/list", data);
    // },
    // // 删除课节
    // classPeriodDel(data) {
    //     return del("/course/teach/lesson/delete/", data);
    // }
    //导出教学数据
    downloadteach(data) {
        return get("/teach/lessonCount/export", data)
    },
    // 获取公开课数据
    getPublicList(data) {
        return get("course/teach/course/public/pageList", data)
    },
    // 获取公开课详情
    getPublicDetail(data) {
        return fetch("course/teach/course/public/getById/", data)
    },
    // 创建公开课
    createPublicCourse(data) {
        return post("course/teach/course/public/add", data)
    },
    // 修改公开课
    editPublicCourse(data) {
        return put("course/teach/course/public/update", data)
    },
    // 删除公开课
    publicCourseDel(data) {
        return del("course/teach/course/public/remove/", data)
    },
    getGradeList(data) {
        return get("course/grade/list", data);
    },
    // 获取所有专家
    getAllTeacher(data) {
        return get("course/query/info/getAllTeacher", data)
    },
    // 获取活动列表
    getActivityList(data) {
        return get("course/activity/myActivity", data)
    },
    // 获取活动列表(管理)
    getManageActivity(data) {
        return get("course/activity/myManageActivity", data)
    },
    // 获取活动详情
    getActivityDetail(data) {
        return fetch("course/activity/detail/", data)
    },
    // 修改活动
    editActivity(data) {
        return put("course/activity", data)
    },
    // 删除活动
    activityDel(data) {
        return del("course/activity/", data)
    },
    // 创建活动
    createAcivity(data) {
        return post("course/activity", data)
    },
    // 获取综合评价数据
    getOverAll(data) {
        return get("course/statistics/count/getOverall", data)
    },
    // 查询项目统计信息
    getSignStatistics(data) {
        return get("course/sign/signStatistics", data)
    },
    // 创建签到
    createSignIn(data) {
        return post("course/sign", data)
    },
    // 创建签到
    editSignIn(data) {
        return put("course/sign", data)
    },
    // 创建签到
    getSignInListOne(data) {
        return fetch("course/sign/", data)
    },
    // 获取签到列表
    getSignInList(data) {
        return get("course/sign/list", data)
    },
    // 批量删除签到列表
    deleteSignIn(data) {
        return Delete("course/sign/batchDelete", data)
    },
    // 获取签到列表
    getNameList(data) {
        return get("course/signUser/pageList", data)
    },
    // 修改签到状态
    editSignInStatus(data) {
        return put("course/signUser/signManage", data)
    },
    //查询签到统计信息（名单列表中）
    getNameListSignStaistics(data) {
        return get("course/sign/signStatisticsBySignId", data)
    },
    // 智慧学堂课程
    getSchoolCourseList(data) {
        return get("course/teach/course/listNew", data)
    },
    // classIn获取直播信息
    getClassInLivingInfo(data) {
        return get("course/teach/lesson/getClassInLivingInfo", data)
    },

    // 获取讨论组
    getDisccusGroup(data) {
        return get("course/item2/groupInfoForComment11", data)
    },
    // 获取讨论列表
    getDisccusList(data) {
        return get("course/teach/comment/listTopic", data)
    },
    // 新建话题
    createDisscus(data) {
        return post("course/teach/comment/createTopic", data)
    },
    // 编辑话题
    editDisscus(data) {
        return post("course/teach/comment/editTopic", data)
    },
    // 话题详情
    getDisscusDetail(data) {
        return get("course/teach/comment/topicOneV2", data)
    },
    // 专家异步课 统计时长
    getLessonExpert(data) {
        return fetch("/course/teach/lesson/getLessonExpert/", data);
    },
    // 教学数据 刷新
    refreshTeachingData(data) {
        return get("/course/teach/lessonCount/refreshCourseCount", data);
    },
    // 答疑解惑 列表
    getExplainList(data) {
        return get("/course/comment/expertQuestions", data);
    },
    // 答疑解惑、我要提问查看回复
    getQuestionOne(data) {
        return get("/course/comment/getQuestionOne", data);
    },
    // 答疑解惑、我要提问 提问
    putQuestion(data) {
        return post("/course/comment/putQuestion", data);
    },
    // 编辑问题
    editQuestion(data) {
        return post("/course/comment/editQuestion", data)
    },
    // 删除问题
    delQuestionOne(data) {
        return Delete("/course/comment/deleteQuestionOne", data);
    },
    // 我的提问
    getMyQuestions(data) {
        return get("/course/comment/myQuestions", data);
    },
    // 其他学员提问
    getOtherQuestions(data) {
        return get("/course/comment/otherQuestions", data);
    },
    // 获取班型
    getClassType(data) {
        return get("/course/teach/course/queryProjectCourseTypeList", data)
    },
    // 查询课程下的人员名单
    getUsersByCid(data) {
        return get("/course/teach/course/getUsersByCid", data)
    },
    // 编辑 classin使用 网页观看的人员
    editPermission(data) {
        return put('/course/teach/course/editPermission', data)
    },
    // 获取沙龙列表(专家/助教)
    getSalonList(data) {
        return get("/seminar/salon/listForExpert2", data)
    },
    // 创建沙龙
    createSalon(data) {
        return post("/seminar/salon/create", data)
    },
    // 获取沙龙授课教师列表（创建/编辑沙龙）
    getSalonTeacher(data) {
        return get("/seminar/salon/usersByProjectId", data)
    },
    // 编辑沙龙
    editSalon(data) {
        return post("/seminar/salon/update", data)
    },
    // 获取沙龙课程详情
    getSalonDetail(data) {
        return fetch("/seminar/salon/seminarInfo/", data)
    },
    // 获取沙龙创建回显数据
    getSalonCreateDetail(data) {
        return fetch("/seminar/salon/findCourseById/", data)
    },
    // 删除沙龙课
    salonDel(data) {
        return del("/seminar/salon/delete/", data)
    },
    // 沙龙课节列表
    getSalonLessonsList(data) {
        return get("/seminar/lesson/getLessonExpert", data)
    },
    // 创建沙龙课节  
    createSalonLesson(data) {
        return post("/seminar/lesson/create", data)
    },
    // 编辑沙龙课节
    editSalonLesson(data) {
        return put("/seminar/lesson/updateLesson", data)
    },
    // 获取沙龙课节详情
    getSalonLessonDetail(data) {
        return fetch("/seminar/lesson/getLessonById/", data)
    },
    // 删除沙龙课节
    salonLessonDel(data) {
        return del("/seminar/lesson/delete/", data)
    },
    // 获取推荐项目列表 
    getRecommendPlatform(data){
        return get("/course/mark/getRecommendPlatform",data)
    },
    // 推荐课程
    addCourseMark(data){
        return post("/course/mark/addCourseMark",data)
    },
    // 推荐回显 /mark/getCourseMark
    getCourseMark(data){
        return get("/course/mark/getCourseMark",data)
    },

    // classin网页观看回显 
    getPermission(data){
        return get('/course/teach/course/getPermission',data)
    }
};

// 默认全部倒出
export default Course;