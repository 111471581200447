// const Score = () => import("../views/manager/score.vue") // 管理员-学分管理
const AddScore = ()=>import("../views/manager/addScore.vue");
const checkScore=()=>import("../views/manager/checkScore.vue");

export default [
    // {
    //     path: "/score",
    //     name: "score",
    //     component: Score,
    //     meta: {
    //         title: "学分管理",
    //         bread:true,
    //         keepAlive: false
    //     }
    // },
    {
        path:'/addScore',
        name:'addScore',
        component:AddScore,
        meta:{
            title:'添加学分制度',
            bread:true,
            keepAlive: false
        }
    },
    {
        path:'/checkScore',
        name:'checkScore',
        component:checkScore,
        meta:{
            title:'查看学分统计',
            bread:true,
            keepAlive: false
        }
    }
]