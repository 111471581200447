<template>
  <span class="uploader-btn" ref="btn" v-show="support">
    <slot></slot>
  </span>
</template>

<script>
import { uploaderMixin, supportMixin } from "./common/mixins";

const COMPONENT_NAME = "uploader-btn";

export default {
  name: COMPONENT_NAME,
  mixins: [uploaderMixin, supportMixin],
  props: {
    upimgload: {
      type: Boolean,
      default: false,
    },
    directory: {
      type: Boolean,
      default: false,
    },
    single: {
      type: Boolean,
      default: false,
    },
    attrs: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    upimgload: (val) => {
      // console.log(this.upimgload);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.uploader.assignBrowse(
        this.$refs.btn,
        this.directory,
        this.single,
        this.attrs
      );
    });
  },
};
</script>

<style>
.uploader-btn {
  display: inline-block;
  position: relative;
  padding: 10px 15px;
  font-size: 100%;
  line-height: 1.4;
  color: #666;
  /* border: 1px dashed #666; */
  cursor: pointer;
  border-radius: 2px;
  background: none;
  outline: none;
}
.upload_box {
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;;
  border: 1px dashed #409eff;
}
.upload_img {
    height: 25px;
    vertical-align: middle;
  }
  .upload_title {
    color: #409eff;
    padding-top:5px;
    font-size: 14px;
    vertical-align: middle;
  }
.upload_box:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
</style>
