//能力体系
const Ability = () =>
    import ("../views/ability/ability_system.vue");
// const keshihua = () =>
//     import ("../../views/ability_system/keshihua.vue");
export default [{
        path: "/ability",
        name: "ability",
        component: Ability,
        meta: {
            title: "能力体系",
            keepAlive: false
        }
    }, 
    // {
    //     path: "/keshihua",
    //     name: "keshihua",
    //     component: keshihua,
    //     meta: {
    //         title: "可视化",
    //     }
    // },
    // {
    //     path: "/report/personal",
    //     name: "ability_report_personal",
    //     component: resolve => require(['../views/ability/report_personal.vue'], resolve),
    //     meta: {
    //         title: "个人能力报告",
    //         keepAlive: true
    //     }
    // },
    // {
    //     path: "/report/improve",
    //     name: "ability_report_improve",
    //     component: resolve => require(['../views/ability/report_improve'], resolve),
    //     meta: {
    //         title: "提升能力",
    //         keepAlive: true
    //     }
    // },
    // {
    //     path: "/ability_improve",
    //     name: "ability_improve",
    //     component: resolve => require(['../views/ability/ability_improvement'], resolve),
    //     meta: {
    //         title: "提升能力copy",
    //         keepAlive: true
    //     }
    // }
];