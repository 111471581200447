// 公用js
// import _this from '../../main'

class Common {
  // 跳转页面
  goPage(url, context, query) {
    context.$router.push(url, query);
  }
  // 监听刷新
  listenRefresh() {
    window.onbeforeunload = function (e) {
      e = e || window.event;
      if (e) {
        e.returnValue = '关闭提示';
      }
      return '关闭提示';
    }
  }
  // 下载文件
  downloadFile(file,type) {
    // console.log(file);
    let address = file.url ? file.url : file.identifier ? file.identifier : file.fileIdentifier;
    let fileToken = VueInstance.$store.state.user.fileToken
    console.log('fileToken--',fileToken)
    // window.open(`${_this.downloadURL}${address}&token=${fileToken}&type=2`)
    if(type){
      window.open(`${VueInstance.downloadURL}${address}&token=${fileToken}&type=${type}`);
    }else{
      window.open(`${VueInstance.downloadURL}${address}&token=${fileToken}&type=2`);
    }
  }
  // 预览
  async preview(file) {
    let videoType = ["avi", "dat", "mkv", "flv", "vob", "mov", "m4v", "3gp", "mp4", "rm", "rmvb", "wmv", "asf", "asx"];

    let address = file.url ? file.url : file.identifier;
    let map = {
      'mp4': 2,
      'mp3': 3,
      'jpg': 1,
      'jpeg': 1,
      'png': 1,
      'doc': 4,
      'docx': 4,
      'pdf': 4,
      'ppt': 4,
      'pptx': 4,
      'xls': 4,
      'xlsx': 4
    }
    // 类型为4的文件 发送预览请求
    if (map[file.ext] === 4) {
      // let resData = await _this.$Api.Resources.checkPreview({
      let resData = await VueInstance.$Api.Resources.checkPreview({
        identifier: address
      });
      if (!resData.data) {
        console.log('预览正在生成请稍后再试')
        DoUpElement.notify(that, 'warning', "预览正在生成请稍后再试")
        return;
      }
      // window.open(`${_this.downloadURL}${file.url}&token=${sessionStorage.getItem('fileToken')}&type=4`)
    }
    // 类型 非4 的文件 浏览器直接打开预览
    if (videoType.indexOf(file.ext.toLowerCase()) != -1) {
      // _this.$router.push({
      VueInstance.$router.push({
        path: "/videodetail",
        query: {
          id: file.identifier
        }
      })
    } else {
      let fileToken = VueInstance.$store.state.user.fileToken
      // window.open(`${_this.downloadURL}${address}&token=${fileToken}&type=4`);
      window.open(`${VueInstance.downloadURL}${address}&token=${fileToken}&type=4`);
    }
  }

  // 将用户组数据变成树形结构
  groupOptionStructure(groupInfo, interfaceCateId) {
    //判断是否为编辑的时候
    let echoTreeArr = [];
    let eachAry;
    //回显分类value转为数组
    if (typeof interfaceCateId == "object") {
      eachAry = interfaceCateId;
    } else if (
      typeof interfaceCateId == "undefined" ||
      typeof interfaceCateId === null
    ) {
      eachAry = [];
    } else {
      eachAry = interfaceCateId.split(",");
    }
    let itemAry = []; //分类树组件，每一项的value数组

    // 递归分类数据
    let recursionCategory = data => {
      let len = data.length;
      for (let i = 0; i < len; i++) {
        //循环data参数，匹配回显的value
        // itemAry.push(data[i].value); //构建分类树数组项,入栈
        itemAry.push(data[i].id); //构建分类树数组项,入栈
        for (let j = 0; j < eachAry.length; j++) {
          //遍历子节点分类value，拼凑成数组项value，并终止循环
          // if (eachAry[j] == data[i].value) {
          if (eachAry[j] == data[i].id) {
            //匹配到子节点value
            echoTreeArr.push(JSON.parse(JSON.stringify(itemAry))); //push进树分类数据
            eachAry.splice(j, 1); //删除以匹配到的value
            break;
          }
        }

        if (eachAry.length <= 0) {
          //所有回显value匹配完成后，跳出循环
          break;
        } else if (data[i].children && data[i].children.length > 0) {
          // 如果存在子分类，递归继续
          recursionCategory(data[i].children);
        }
        itemAry.pop(); //出栈
      }
    };
    recursionCategory(groupInfo); //调用递归
    // this.userGroup = echoTreeArr;
    return echoTreeArr;
  }
  //将数字月份转换成英文月份
  transformEnglish(month) {
    var data = month.split("")[1];
    var enArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var _month = enArr[data - 1]
    return _month
  }
  //将时间戳转换成 YYYY-MM-dd HH:mm:ss
  formatTimestamp(time) {
    if (String(time).length !== 13) {
      var date = new Date(time * 1000);
    } else {
      var date = new Date(time);
    }
    var YY = date.getFullYear() + '-';
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return YY + MM + DD + " " + hh + mm + ss;
    // return YY + MM + DD +" "+hh + mm;
  }
  //将时间YYYY-MM-dd HH:mm:ss 转换成时间戳
  formatTime(time) {
    var date = time;
    date = date.substring(0, 19);
    date = date.replace(/-/g, '/'); //必须把日期'-'转为'/'
    var timestamp = new Date(date).getTime();
    return timestamp
  }

  tableIndexMethod(index, page, size) {
    return page > 1 ? (size * (page - 1)) + index + 1 : index + 1
  }

}

export default new Common();