<template>
<!-- 课程详情页 讨论部分 讨论区主体列表 -->
  <div class="course-discuss-area">
    <div class="comments">
      <!-- 一级留言 -->
      <div class="summary flex-align-between">
        <!-- 个人信息 -->
        <div class="personal-info flex-align f20">
          <img class="avatar" :src="downloadURL+comment.userAvatar" alt=""  :onerror="defualtImg">
          <p class="name">{{comment.userName}}</p>
        </div>
        <!-- 右侧功能条 -->
        <div class="toolbar flex-layout">
          <span class="time" style="color:#666">{{comment.createTime}}</span>
          <button class="btn" @click="reply(comment)">回复</button>
          <button class="btn" v-if="comment.userName === userInfo.name" @click="deleteReply(comment)">删除</button>
        </div>
      </div>
      <!-- 留言内容 -->
      <div class="reply-content">{{comment.content}}</div>
      <!-- 留言回复框 -->
      <div class="textarea-container parent-textarea" v-if="$store.state.discuss.replyArea === comment.id">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="回复："
          resize="none"
          v-model="textarea"
          class="textarea">
        </el-input> 
        <div class="control-textarea">
           <el-button @click="$store.state.discuss.replyArea = -1">取 消</el-button>
           <el-button type="primary" :plain="true" @click="submitReply(comment)">提 交</el-button>
        </div>
      </div>
      <!-- 二级子留言 -->
      <div class="child-comment" v-for="(item,index) in list" :key="index">
          <div class="child-item flex-between">
            <!-- 个人头像、回复信息 -->
            <div class="person-info flex-start">
              <img :src="downloadURL+item.userAvatar" alt="" class="avatar" :onerror="defualtImg"> 
              <p class="f16 responder">{{item.userName}}<span class="replay-text" style="color:#2373EC">回复</span>{{item.parentUserName}}：</p>
              <p class="child-content">{{item.content}}</p>
            </div>
            <!-- 右侧工具栏 -->
            <div class="toolbar">
              <span class="time">{{item.createTime}}</span>
              <button class="btn" @click="reply(item)">回复</button>
              <button class="btn" v-if="item.userName === userInfo.name" @click="deleteReply(item)">删除</button>
            </div>
          </div>
          <!-- 子级留言回复框 -->
          <div class="textarea-container" v-if="$store.state.discuss.replyArea === item.id">
             <el-input
              type="textarea"
              :rows="2"
              placeholder="回复："
              resize="none"
              v-model="textarea"
              class="textarea">
            </el-input> 
            <div class="control-textarea">
               <el-button @click="$store.state.discuss.replyArea = -1">取 消</el-button>
               <el-button type="primary" :plain="true" @click="submitReply(item)">提 交</el-button>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  inject:['reload'],
  props: {
    comment:{
      type:Object,
      default:{}
    }
  },
  data() {
    
    return {
      list:[],//讨论列表
      textarea:'',//回复内容
      userInfo:{},//个人信息
      defualtImg:`this.src="${require("@/assets/images/home/avatar.png")}"`,//默认图片
      dialogDeleteVisible:false,//删除对话框展示
    }
  },
  methods:{
    // 处理讨论列表
    handleComment(array){
      let _this = this
      if(array.length > 0 && array.length){
        array.forEach(function(item,index){
          _this.list = _this.list.concat(item);
          _this.handleComment(item.children);
        });
      } else {
        return
      }
    },
    // 删除讨论列表子项（删除脏数据）
    deleteChildren(){
      this.list.forEach((item,index)=>{
        item.children = []
      })
    },
    // 回复
    reply(item){
      // console.log(item.id)
      this.$store.state.discuss.replyArea = item.id
    },
    // 提交回复
    async submitReply(item) {
      if(!this.textarea.trim()) {
        this.$alert('填写内容不可为空', '提示', {
          confirmButtonText: '确定',
        });
        return
      }
      let params = {
        courseId:this.$route.params.id || 1,
        parentId:item.id,
        content:this.textarea,
      }
      // console.log(params);
      let resData = await this.$Api.Course.getcreate(params);
      
      if(resData.code === 200 && resData.msg === "请求成功") {
        this.$message({
          message: '回复成功',
          type: 'success'
        });
        this.currentReply = 0;
        setTimeout(()=>{
           this.$store.state.discuss.replyArea = -1
           this.$emit("replay",true);
           this.reload();
        },100)
      } else {
        this.$message.error(resData.msg);
      }
    },
    // 删除留言
    deleteReply(item) {
        this.$confirm('是否删除该留言?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // let resData = this.$Api.Course.commentDel(item.id)
        this.$Api.Course.commentDel(item.id).then((resData)=>{
          //  console.log(resData)
            if(resData.code === 200 && resData.msg === "请求成功") {
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              setTimeout(()=>{
                this.$emit("replay",123);
                this.reload();
              },100)
            }else {
              this.$message.error(resData.msg);
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        }); 
    },
    //页面初始化
    init(){
      this.userInfo = this.$store.state.user.userInfo;
      if(this.comment.children === "") return
      this.handleComment(this.comment.children);
      // this.deleteChildren();
    }
  },
  mounted(){
    this.init();
  },
  activated(){
    this.init();
  },
  deactivated(){}
};
</script>

<style lang="less" scoped>

/*媒体查询*/
/*当页面大于1200px 时，大屏幕，主要是PC 端*/
// @media (min-width: 1200px) {
//   .course-discuss-area {
//     // width: 1152px;
//   }
//   .parent-content {
//     // width: 986px;
//   }
// }

/*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
// @media (min-width: 992px) and (max-width: 1199px) {
//   .course-discuss-area {
//     // width: 852px;
//     .comments {
//       .child-comment {
//         margin-left: 35px;
      
//       .child-content {
//          max-width: 250px;
//        }
//       }
//     }
//   }
//   .reply-content {
//     // width: 766px;
//   }
// }


.course-discuss-area {
  // background-color: rgba(248, 248, 248, 0.7);
  border: 1px solid #F9F9F9;
  border-radius: 4px;
  margin: 0 auto 29px;
  .comments {
    padding: 20px 30px 26px 22px;

    .avatar {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      margin-right: 11px;
    }

    .name{
      font-size: 12px;
      font-weight: 500;
    }

    .toolbar {
      flex-shrink: 0;
      padding-top: 8px;
      font-size: 12px;
      .btn {
        margin-left: 35px;
        font-size: 12px;
      }
    }

    .reply-content {
      margin: 15px auto;
      border-radius: 4px;
      padding: 16px;
      // max-width: 1000px;
      min-height: 44px;

      color: #666;
      font-size: 12px;

      background-color: #f9f9f9;
    }

    .child-comment {
      margin-bottom: 15px;
      .avatar {
        flex-shrink: 0;
        width: 33px;
        height: 33px;
      } 

      .child-content {
        word-wrap: break-word;
      }
    }

    .responder {
      flex-shrink: 0;
      line-height:33px;
      font-size: 12px;
      .replay-text {
        margin: 0 7px;
      }
    }

    .child-content {
      display: inline-block;
      padding-top: 6px;
      padding-right: 10px;
      // max-width: 500px;
    }

    .textarea-container {
      max-width: 1000px;
      margin: 15px 0;
      
      &.parent-textarea {
        margin:15px auto;
      }

      .control-textarea {
        margin-top:10px;
        text-align: right;
        /deep/ .el-button {
          font-size: 12px;
          padding: 8px 20px;
        }
      }

      .textarea {
        display: block;
        font-size: 16px;
      }
    }

    .btn {
      cursor: pointer;
    }
  }
}
</style>