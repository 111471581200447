"use strict";
//公共
import headers from './community/header.vue' //头部
import Footer from './community/footer.vue' //底部
import Pagination from "./community/pagination.vue"; //分页
import TeacherSelect from "./community/teacherSelect.vue"; //教师选择input
import SubjectSelect from "./community/subjectSelect.vue"; //学科选择input
import CourseTypeSelect from "./community/courseTypeSelect.vue"; //课程类型选择input
import UserGroup from "./community/userGroup.vue"; //课程类型选择input
import DateTimePikcer from "./community/dateTimePicker.vue"; //日期选择
import Empty from "./community/empty.vue"; //暂无内容
import Breadcrumb from "./community/breadcrumb.vue"; //暂无内容
import VideoPlay from "./videoplay/video.vue"; //视频播放
import FileVideo from "./videoplay/filevideo.vue"; //视频播放

//中心首页
import ClassTips from './center/dialog.vue' //专家上课提示弹窗
import Schedule from './center/schedule.vue' //课程表
import ToLiveMode from './center/class_mode.vue' // 选择上课方式弹窗
import discuss from './center/discuss.vue' // 讨论模块
import discussArea from './center/discuss_area.vue' // 讨论模块
import XjTips from './center/xj_dialog.vue' // 讨论模块
import cardList from './center/card_list.vue' // 我的课程、作业、活动列表页共组件
import proTitle from './center/pro-title.vue' //当前项目title

//个人中心 
// import mySideBar from './my/my_sidebar.vue'

//上传
import Uploader from "./uploader/uploader.vue";
import UploaderBtn from "./uploader/btn.vue";
import UploaderDrop from "./uploader/drop.vue";
import UploaderUnsupport from "./uploader/unsupport.vue";
import UploaderList from "./uploader/list.vue";
import UploaderFiles from "./uploader/files.vue";
import UploaderFile from "./uploader/file.vue";
import UploaderImage from "./uploader/image_upload.vue";
import UpImg from "./uploader/upimg.vue";
//oss上传
import OssUploader from "./ossuploader/oss-uploader.vue";
import OssUploadCover from "./ossuploader/oss-uploadcover.vue";

import UploadFile from "./community/uploadFile.vue";
import UploadCover from "./community/uploadCover.vue";
import UploadAnyFile from "./community/uploadAnyFile.vue"

//引导页
import GuidePage from "./guidepage/guidepage"


const Coms = {
  headers,
  Footer,
  Pagination,
  TeacherSelect,
  SubjectSelect,
  CourseTypeSelect,
  UserGroup,
  DateTimePikcer,
  Empty,
  Breadcrumb,
  VideoPlay,
  FileVideo,

  ToLiveMode,
  ClassTips,
  Schedule,
  discuss,
  discussArea,
  XjTips,
  proTitle,
  cardList,
  OssUploader,//oss上传组件
  OssUploadCover,//Oss上传弹窗抽屉
  // mySideBar,
  Uploader,
  UploaderBtn,
  UploaderDrop,
  UploaderUnsupport,
  UploaderList,
  UploaderFiles,
  UploaderFile,
  UploaderImage,
  UpImg,
  UploadFile,
  UploadCover,//上传弹窗抽屉
  UploadAnyFile,

  GuidePage
};

// vue批量引入全局组件
const PageComs = {
  install(Vue) {
    for (let key in Coms) {
      Vue.component(key, Coms[key]);
    }
    Vue.component(UploaderImage.name, UploaderImage)
    Vue.component(Uploader.name, Uploader)
    Vue.component(UploaderBtn.name, UploaderBtn)
    Vue.component(UploaderDrop.name, UploaderDrop)
    Vue.component(UploaderUnsupport.name, UploaderUnsupport)
    Vue.component(UploaderList.name, UploaderList)
    Vue.component(UploaderFiles.name, UploaderFiles)
    Vue.component(UploaderFile.name, UploaderFile)
    Vue.component(UpImg.name, UpImg)
  }
};

export default PageComs;