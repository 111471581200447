import {
  get,
  put,
  patch,
  post,
  fetch,
  del,
  Delete,
  formDataPost
} from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
const Activity = {
  //报名情况列表
  getApplyList(data) {
    return get("/course/apply/applyListUser", data);
  },
  //报名、取消报名
  apply(data) {
    return post("/course/apply/apply", data);
  },
  //获取报名状态 
  getApplyStatus(data) {
    return fetch("/course/apply/getApplyStatus/", data);
  },
  // 活动详情
  getActiveDetail(data) {
    return fetch("/course/activity/", data)
  },
  // 根据活动id获取阶段列表
  getStageList(data) {
    return get("/course/activity/queryStageList/", data)
  },
  // 开始答题
  getQuest(data) {
    return get("/quest/qb/startPractice", data)
  },
  // 回答问题
  answer(data) {
    return post("/quest/qb/answerQuest", data)
  },
  // 提交问卷
  subQuest(data) {
    return put("/quest/qb/commitQuest", data)
  },
  // 成绩查询
  getResult(data) {
    return fetch("/course/activity/checkRecord/", data)
  },
  // 获取活动列表 （旧）
  // getActivityList(data) {
  //   return get("/course/activity/queryListByProjectId", data)
  // },
  // 查看活动详情（旧）
  // getActivityDetail(data) {
  //   return fetch("/course/activity/", data)
  // },
  // 获取活动列表 （新 / 我创建的）
  getActivityList(data) {
    return get("/course/activity/myManageActivity", data)
  },
  // 获取活动详情 （新）
  getActivityDetail(data) {
    return fetch("/course/activity/detail/", data)
  },

  // 删除活动
  delActivity(data) {
    return del("/course/activity/", data)
  },
  // 查询活动环节筛选情况
  getStageSortList(data) {
    return fetch("/course/activity/queryParams/", data)
  },
  // 查询已交作品用户列表
  getWorkUserList(data) {
    return get("/course/activity/queryHistoryUsers", data)
  },
  // 查看单个用户上传的作品信息
  getUserWork(data) {
    return get("/course/activity/queryHistory", data)
  },
  // 作品打分
  scoreRecord(data) {
    return post("/course/activity/scoreRecord", data)
  },
  // 共度资源圈
  getResouce(data) {
    return get("/course/activity/queryCourses", data)
  },
  // 测评结果
  testResult(data) {
    return get("quest/qb/evaluationResult", data)
  },
  // 根据环节id查询对应的比赛信息
  getGameInfo(data) {
    return fetch("course/activity/stage/", data)
  },
  // 正式比赛查询是否答过题
  isAnswer(data) {
    return fetch('course/activity/checkIsFinish/', data)
  },
  // 是否能参与模拟练习
  checkMock(data) {
    return fetch('/quest/qb/checkMock/', data)
  },
  // 大赛报告(个人模拟)
  getReport(data) {
    return get('quest/qb/sockStatistics', data)
  },
  // 大赛报告(正式比赛)
  getReport_official(data) {
    return get('quest/qb/examStatistics', data)
  },
  // 判断是否通过初赛
  isPass(data) {
    return fetch('course/activity/checkUserInStage/', data)
  },
  //大赛报告模拟学情分析数据 -- 领导
  getLeaderActiveReportInfo(data) {
    return get("/quest/qb/queryInfo", data)
  },
  // 获取区域模拟--参赛人员分布数据
  getAreaMemberInfo(data) {
    return get("/quest/qb/areaMemberInfo", data)
  },
  // 获取区域模拟--成绩分布数据
  getScoreInfo(data) {
    return get("/quest/qb/scoreInfo", data)
  },
  // 获取区域模拟--男女比例
  getGenderInfo(data) {
    return get("/quest/qb/genderInfo", data)
  },
  // 获取区域模拟--组别比例
  getLevelInfo(data) {
    return get("/quest/qb/levelInfo", data)
  },
  // 获取区域模拟--活动开始、结束时间
  getStageTime(data) {
    return get("/quest/qb/stageTime", data)
  },
  // 获取活动的每日信息（模拟数据中 折线图的部分数据）
  getDaysInfo(data) {
    return get("/quest/qb/daysInfo", data)
  },
  // 大赛报告个人能力
  getPower(data) {
    return get('quest/qb/personalAbility', data)
  },
  // 个人模拟折线图
  getLineCharts(data) {
    return get('quest/qb/personalDayInfo', data)
  },
  // 获取区域模拟筛选信息 -- 区域 & 学校
  getFieldAreaAndSchool(data) {
    return get("/quest/qb/getFieldAreaAndSchool", data)
  },
  // 获取区域模拟筛选信息 -- 班级 & 角色
  getFieldGradeAndRole(data) {
    return get("/quest/qb/getFieldGradeAndRole", data)
  },
  // 获取老师下的班级筛选信息
  getTeacherClassInfo(data) {
    return get("/quest/qb/teacherClassInfoInStage", data)
  },
  // 获取活动包含的活动成员
  getActivityMember(data) {
    return get("/course/activity/getActivityMember", data)
  },
  // 获取当前活动下的子活动列表
  getChildActivity(data) {
    return get("/course/activity/getChildActivity", data)
  },
  // 创建学习墙子活动
  createStudyWallChildActivity(data) {
    return post("/course/activity/addStudywall", data)
  },
  // 编辑学习墙子活动
  editStudyWallChildActivity(data) {
    return put("/course/activity/editStudywall", data)
  },
  // 删除学习墙子活动
  deleteStudyWallChildActivity(data) {
    return del("/course/activity/deleteStudywall/", data)
  },
  // 获取我参与的活动列表
  getMyActivityList(data) {
    return get("/course/activity/myActivity", data)
  },
  // 获取我的活动下 -- 作品评选列表 （参训教师）
  getMyEvaluationActivity(data) {
    return get("/course/activity/myEvaluationActivity", data)
  },
  // 获取专家活动列表页/参训教师我的活动页 跳转优字地址
  getGoYzHomeUrl(data) {
    return get("/course/activity/goYzHome", data)
  },
  // 获取子活动创建时 跳转学习墙地址
  getGoYzWallUrl(data) {
    return get("/course/activity/goYzWall", data)
  },
  // 获取专家活动列表页/参训教师我的活动页 跳转作品集地址
  getGoYzTotalCollectionUrl(data) {
    return get("/course/activity/goYzTotalCollection", data)
  },
  // 获取子活动 进入活动地址
  getGoYzJoin(data) {
    return get("/course/activity/goYzJoin", data)
  },
  // 获取子活动 作品集地址
  getGoYzCollection(data) {
    return get("/course/activity/goYzCollection", data)
  },
  // 活动统计数据
  getActivityData(data) {
    return get("/course/activity/getActivityData", data)
  },
  // 子活动数据
  getChildActivityList(data) {
    return get("/course/activity/childActivityList", data)
  },
  // 导出活动数据(子活动)
  exporActivityData(data) {
    return get("/course/activity/childActivityListEx/export", data)
  },
  // 活动管理-活动数据
  getActivityCountList(data) {
    return get("/course/activity/activityCountList", data)
  },
  // 导出活动数据
  exportActivityCountList(data) {
    return get("/course/activity/activityCountListEx/export", data)
  },
  // 大赛类活动创建 绑定课程列表
  getListForActivityCreate(data) {
    return get("/course/teach/course/listForActivityCreate", data)
  },
  // 大赛类活动 创建子活动 获取年级列表
  getStageGrade(data) {
    return get("/course/activity/stageGrade", data)
  },
  // 大赛类活动 创建子活动 获取书籍列表
  getSelectBookList(data) {
    return post("/quest/qb/selectBook", data)
  },
  // 大赛类活动 创建子活动 环节能力标准
  getStageAbilityList(data) {
    return get("/course/activity/stageAbilityList", data)
  },
  // 大赛类活动 创建子活动
  createStageForMatch(data) {
    return post("/course/activity/stage/create", data)
  },
  // 大赛类活动 编辑子活动
  editStageForMatch(data) {
    return put("/course/activity/stage/update", data)
  },
  // 大赛类活动 删除子活动
  delStageForMatch(data) {
    return Delete("/course/activity/stage", data)
  },
  // 大赛类导入环节人员名单
  uploadUser(data) {
    return formDataPost("/course/activity/stage/uploadUser", data)
  },
  // 大赛类活动 活动数据查询
  getMatchData(data) {
    return get("/course/activity/activityData", data)
  },
  // 大赛类活动 活动数据生成
  createStageData(data) {
    return get("/quest/qb/createStageData", data)
  },
  // 大赛类活动 查询环节 生成数据 是否完成
  isFinishCreate(data) {
    return get("/quest/qb/isFinishCreate", data)
  },
  // 大赛类活动 下载环节数据文件
  downloadPackData(data) {
    return get("/quest/qb/download/export", data)
  },
  // 大赛类活动 获取驰声初始化参数
  getInitParam(data) {
    return get("/quest/qb/getInitParam", data)
  }


};

// 默认全部倒出
export default Activity;