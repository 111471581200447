// 课程数据存储
const state = {
  defaultCourseCover: [ //默认课程cover图片 列表页
    `this.src="${require("@/assets/images/course/course-cover01.png")}"`,
    `this.src="${require("@/assets/images/course/course-cover02.png")}"`,
    `this.src="${require("@/assets/images/course/course-cover03.png")}"`,
    `this.src="${require("@/assets/images/course/course-cover04.png")}"`,
    `this.src="${require("@/assets/images/course/course-cover05.png")}"`,
    `this.src="${require("@/assets/images/course/course-cover06.png")}"`,
  ],
  defaultCourseBigCover: [ //默认课程cover图片 详情页
    `this.src="${require("@/assets/images/course/course-bigcover01.png")}"`,
    `this.src="${require("@/assets/images/course/course-bigcover02.png")}"`,
    `this.src="${require("@/assets/images/course/course-bigcover03.png")}"`,
    `this.src="${require("@/assets/images/course/course-bigcover04.png")}"`,
    `this.src="${require("@/assets/images/course/course-bigcover05.png")}"`,
    `this.src="${require("@/assets/images/course/course-bigcover06.png")}"`,
  ],
  defaultActivityCover: ` this.src="${require("@/assets/images/course/activity-cover.png")}"`, //默认活动cover图片 列表页
  defaultActivityBigCover: ` this.src="${require("@/assets/images/course/activity-bigcover.png")}"`, //默认活动cover图片 详情页
  defaultActivityBigCoverStudyWall: ` this.src="${require("@/assets/images/active/activityBigCover.png")}"`, //默认活动cover图片 详情页
  defaultActivityCoverStudyWall: ` this.src="${require("@/assets/images/active/active-cover.png")}"`, //默认活动cover图片 详情页
  defaultTaskCover: ` this.src="${require("@/assets/images/course/task-cover.png")}"`, //默认作业cover图片 列表页
  defaultTaskBigCover: ` this.src="${require("@/assets/images/course/task-bigcover.png")}"`, //默认作业cover图片 详情页
  currentActivityType: 1, // 当前我的活动停留的标签页 1 我创建的 0我参与的
  markMap: { //课程状态Map (课程详情右上角)
    // 同步课
    2: {
      text: "进入直播",
      class: "",
      lessonStatus: {
        text: "直播中",
        class: "btnconduct"
      },
    },
    3: {
      text: "进入直播",
      class: "btn-no",
      lessonStatus: {
        text: "未开始",
        class: ""
      },
    },
    // 异步课
    11: {
      text: "开始学习",
      class: "",
      lessonStatus: {
        text: "已开放",
        class: "finish"
      },
    },
    12: {
      text: "开始学习",
      class: "btn-no",
      lessonStatus: {
        text: "未开放",
        class: "finish"
      },
    },
  },
  courseMarkMap: { //课程状态Map (课程表、课程目录)
    // 同步课
    1: {
      text: "未预约",
      class: "btn-grey",
      lessonStatus: {
        text: "未开始",
        class: ""
      },
    },
    2: {
      text: "进入直播",
      class: "btn-blue",
      lessonStatus: {
        text: "直播中",
        class: "living"
      },
    },
    3: {
      text: "进入直播",
      class: "btn-grey",
      lessonStatus: {
        text: "未开始",
        class: ""
      },
    },
    4: {
      text: "观看回放",
      class: "btn-blue",
      lessonStatus: {
        text: "已结束",
        class: ""
      },
    },
    5: {
      text: "观看回放",
      class: "btn-grey",
      lessonStatus: {
        text: "已结束",
        class: "finish"
      },
    },
    // 异步课
    11: {
      text: "开始学习",
      class: "btn-blue",
      lessonStatus: {
        text: "已开放",
        // class:"finish"
        class: "open"
      },
    },
    12: {
      text: "开始学习",
      class: "btn-grey",
      lessonStatus: {
        text: "未开放",
        // class:"finish"
        class: ""
      },
    },
  },
};

const mutations = {
  getActivityType(state, res) {
    console.log('res:',res)
    state.currentActivityType = res
  },
};

export default {
  state,
  mutations
}