<template>
    <div class="date-time-picker">
        <el-col :span="4">
            <el-form-item prop="date">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期"
                  v-model="date"
                  style="width: 100%;"
                  :picker-options="pickerOptions"
                  :disabled="isDisabledDate"
                   @change="handleDateChange"
                ></el-date-picker>
            </el-form-item>
        </el-col>
        <el-col :span="10" v-if="pickerType === 'during'">
            <el-form-item prop="during" label-width="40px">
                <el-time-picker
                  is-range
                  v-model="during"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  :disabled="isDisabledTime"
                  @change="handleDuringChange"
                  value-format="timestamp"
                ></el-time-picker>
            </el-form-item>
        </el-col>
        <el-col :span="4" v-if="pickerType === 'point'">
              <el-form-item prop="time" label-width="40px">
                <el-time-picker
                  v-model="time"
                  :disabled="isDisabledTime"
                  @change="handleTimeChange"
                  value-format="HH:mm:ss"
                ></el-time-picker>
              </el-form-item>
        </el-col>
    </div>
</template>

<script>
export default {
    props:{
        // 选择器类型 （'during'时间段 / 'point'时间点）
        pickerType: {
            type: String,
            default: 'during' //默认时间段 
        },
        beginTime:{
            type: Number
        },
        endTime:{
            type: Number
        },
        isDisabled:{
             type: Boolean,
             default:false,
        },
    },
    data(){
        return {
            date:'', //日期
            during:'', //时间段
            time:'', //时间
            isDisabledDate:false,
            isDisabledTime:true,
            pickerOptions: { //今天以前（不含今天）禁用
                disabledDate(time) {
                  return time.getTime() < Date.now() - 8.64e7;
                }
            },
        }
    },
    watch:{
        isDisabled(newVal,oldVal) {
            if(newVal) {
                this.isDisabledDate = true;
                this.isDisabledTime = true;
            }
        },
        beginTime(newVal,oldVal){
            if(!newVal) return
            this.during = [];
            this.date = this.dayjs(this.beginTime*1000).format('YYYY-MM-DD')
            if(this.pickerType === 'during') {
                this.during.push(this.beginTime*1000)
                this.during.push(this.endTime*1000)
            } else if(this.pickerType === 'point') {
                this.time = this.dayjs(this.beginTime*1000).format('HH:mm:ss')
            }
           
        },
        endTime(newVal,oldVal){
            if(!newVal) return
            this.during = []
            this.during.push(this.beginTime*1000)
            this.during.push(this.endTime*1000)
        },
        date(newVal,oldVal) {
            // 首次进来 时间不可选
            // if(!oldVal && !this.beginTime) {
            //     this.isDisabledTime = true;
            //     return
            // }

            // 如果有新值 判断是否为是否禁用
            if(newVal) {
                this.isDisabledTime = false;
                if(this.isDisabled) {
                    this.isDisabledDate = true;
                    this.isDisabledTime = true;
                }
            } else { //新值为空初始化选择器和数据
                this.initDate();
            }
        },
    },
    methods:{
        initDate(){
            this.isDisabledDate = false,
            this.isDisabledTime = true;
            this.during = '';
            this.time = '';
        },
        handleDateChange(val) {
            if(val) {
                let _beginTime = this.dayjs(`${val} ${this.dayjs(this.during[0]).format('HH:mm:ss')}`).unix();
                let _endTime = this.dayjs(`${val} ${this.dayjs(this.during[1]).format('HH:mm:ss')}`).unix();
                this.$emit('dateTimePicker', { bTime: _beginTime, eTime: _endTime })
            } else {
                if(this.pickerType === 'during') {
                    this.$emit('dateTimePicker', { bTime: 0, eTime: 0 })
                } else if(this.pickerType === 'point') {
                    this.$emit('dateTimePicker', { bDate: 0, bTime: 0 })
                }
            }
            
        },
        handleDuringChange(val){
            if(!val) return
            let _beginTime = val ? this.dayjs(`${this.date} ${this.dayjs(val[0]).format('HH:mm:ss')}`).unix() : '';
            let _endTime = val ?this.dayjs(`${this.date} ${this.dayjs(val[1]).format('HH:mm:ss')}`).unix() : '';
            this.$emit('dateTimePicker', { bTime: _beginTime, eTime: _endTime })
        },
        handleTimeChange(val){
            // console.log(val);
            let _beginDate = val ? `${this.date} 00:00:00`: '';
            let _beginTime = val ? this.dayjs(`${this.date} ${val}`).unix() : '';
            this.$emit('dateTimePicker', { bDate: _beginDate, bTime: _beginTime })
        },
    },
    deactivated(){
        this.date=''; 
        this.during=''; 
        this.time='';
        this.isDisabledDate=false
        this.isDisabledTime=true
    },
}
</script>

<style>

</style>