//智慧学堂
const School = () => import("../views/school/school_home.vue");
const SchoolList = () => import("../views/school/school_list.vue");
const SchoolOne = () => import("../views/school/school_oneList.vue");

export default [{
        path: "/school",
        name: "school",
        component: School,
        meta: {
            title: "智慧学堂",
            keepAlive: true
        },
        // redirect: {
        //     name: "schoolList"
        // },
        children: [{
            path: "list",
            name: "schoolList",
            // component: resolve => require(['../views/school/school_wholeList.vue'], resolve),
            component: SchoolList,
            meta: {
                title: "智慧学堂",
                keepAlive: true
            }
        }, {
            path: "one",
            name: "schoolOne",
            // component: resolve => require(['../views/school/school_oneList.vue'], resolve),
            component: SchoolOne,
            meta: {
                title: "智慧学堂-列表",
                keepAlive: true
            }
        }]
    }
];