<template>
  <textarea :id="tinymceId" style="visibility: hidden" ref="tiny"/>
</template>

<script>
import loadTinymce from "@/utils/loadTinymce"
import { plugins, toolbar } from "./config"
import { debounce } from "throttle-debounce"

let num = 1

export default {
  props: {
    id: {
      type: String,
      default: () => {
        num === 10000 && (num = 1)
        return `tinymce${+new Date()}${num++}`
      }
    },
    value: {
      default: ""
    }
  },
  data() {
    return {
      tinymceId: this.id,
      cutFlag:false,
      note : '',
      // pasteText:'',
      
    }
  },
  mounted() {
    loadTinymce(tinymce => {
      // eslint-disable-next-line global-require
      require("./zh_CN")
      let conf = {
        selector: `#${this.tinymceId}`,
        language: "zh_CN",
        menubar: "file edit insert view format table",
        plugins,
        toolbar,
        height: 300,
        branding: false,
        object_resizing: false,
        end_container_on_empty_block: true,
        powerpaste_word_import: "clean",
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: "square",
        advlist_number_styles: "default",
        default_link_target: "_blank",
        link_title: false,
        nonbreaking_force_tab: true,
        external_plugins:{
          'wordlimit': 'https://cdnyun.cnuen.net/plugin.min.js'
        },
      }
      conf = Object.assign(conf, this.$attrs)
      if(this.$attrs.init) {
        conf = Object.assign(conf, this.$attrs.init)
      }
      conf.init_instance_callback = editor => {
        document.getElementsByClassName("tox-statusbar__wordcount")[0].click();
        if (this.value) editor.setContent(this.value)
        if (this.$attrs.init) this.wordLimit(editor)
        this.vModel(editor)
      };
      tinymce.init(conf)
    });
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    vModel(editor) {
      // 控制连续写入时setContent的触发频率
      const debounceSetContent = debounce(250, editor.setContent)
      this.$watch("value", (val, prevVal) => {
        if (editor && val !== prevVal && val !== editor.getContent()) {
          if (typeof val !== "string") val = val.toString()
          debounceSetContent.call(editor, this.cutFlag ? this.note : val);
          this.cutFlag = false;
          editor.selection.select(editor.getBody(),true);
          editor.selection.collapse(false);
          
        }
      })

      editor.on("change keyup undo redo", () => {
        this.$emit("input", editor.getContent())
      });
    },
    wordLimit(editor) {
      editor.on('wordlimit', (e)=> {
            // e.maxCount   // 配置的最大输入字数
            // e.wordCount  // 已输入的字数
            // e.preCount    // 粘贴进来的内容字数，可以用来单独提示粘贴内容时超出的计算
            // e.isPaste       // 是否是粘贴输入
            // console.log(e);
            var beyond = 0;
            if(e.wordCount > e.maxCount) {
                beyond = e.wordCount - e.maxCount;
            }
            if(e.isPaste) {
                let html = editor.getContent();
                let re1 = new RegExp("<.+?>","g");
                let txt = html.replace(re1,'');
                txt = txt.replace(/\n/g,'');
                txt = txt.replace(/&nbsp;/g,' ');
                // console.log(txt.length);
                beyond = e.preCount - e.maxCount;
                if(txt.length > e.maxCount) {
                  this.cutFlag = true;
                  editor.setContent('');
                  this.note = txt.substring(0,e.maxCount);
                  editor.selection.select(editor.getBody(),true);
                  editor.selection.collapse(false);
                }
            }
            
            // 可以吧alert换成自己的提示组件
            this.$message.error(`最多只能输入${e.maxCount}个字${beyond > 0 ?`，已超出${beyond}个字，超出部分无法保存。`:'。'}`)
            // alert('最多只能输入'+ e.maxCount +'个字' + (beyond > 0 ? '，已超出'+ beyond +'个字，超出部分无法保存' : '。'));

        });
    },
    // listenPaste() {
    //   // 监听复制的内容
    //    this.$nextTick(()=>{
    //     let iframe = document.querySelector('.tox-edit-area__iframe');
    //     let iframeWindow = iframe.contentWindow || iframe.contentDocument.parentWindow
    //     let tiny = iframeWindow.document.getElementById('tinymce')
    //     let text;
    //     tiny.addEventListener('paste',(event)=>{
    //        text = (event.clipboardData || windwo.clipboardData).getData('text');
    //        this.pasteText = text;
    //        console.log(this.pasteText);
    //     })
        
    //   })
    // },
    destroyTinymce() {
      if (!window.tinymce) return
      const tinymce = window.tinymce.get(this.tinymceId)
      if (tinymce) {
        tinymce.destroy()
      }
    }
  }
}
</script>
