import {
    get,
    put,
    patch,
    post,
    fetch,
    del,
    Delete,
    formDataPost
} from "../ApiDecorator"; // 倒入 api
// 单独倒出
const Score = {
    // 获取角色参数
    getRoles(data){
        return get("/score/sc/getQueryParam",data)
    },
    // 学分管理列表
    getScoreList(data){
        return get("/score/sc/list",data)
    },
    // 添加学分制度
    addScore(data){
        return post("/score/sc",data)
    },
    // 编辑学分制度
    editScore(data){
        return put('/score/sc',data)
    },
    // 发布学分制度
    publishScore(data){
        return put('/score/sc/publish',data)
    },
    // 删除学分制度
    delScore(data){
        return Delete('/score/sc',data)
    },
    // 查看学分制度
    viewScore(data){
        return get('/score/sc/getById',data)
    },
    // 查询项目信息
    projectInfo(data){
        return get('/learn/project/selectDetail',data)
    },
    // 培训记录
    stuLog(data){
        return get('/learn/project/userProjects',data)
    },
    // 同步学习记录
    syncLog(data){
        return get('/score/projectHome/getLiveCourseStudyInfoOne',data)
    },
    // 异步学习记录
    asyncLog(data){
        return get('/score/projectHome/getRecordCourseStudyInfoOne',data)
    },
    // 活动记录
    activityLog(data){
        return get('/score/projectHome/getActivityInfoOne',data)
    },
    // 浏览记录
    browseLog(data){
        return get('/fu/center/userHistory',data)
    },
    // 学分排行记录
    rankLog(data){
        return get('/score/sc/userScoreHistory',data)
    },
    // 我的学分
    myScore(data){
        return get('/score/sc/userProjectScoreHistory',data)
    },
    // 积分排行榜
    scoreRank(data){
        return get('/score/sc/scoreHistory',data)
    }
};

// 默认全部倒出
export default Score;