<template>
  <div class="uploader">
    <slot :files="files" :file-list="fileList" :started="started">
      <uploader-unsupport></uploader-unsupport>
      <uploader-drop>
        <uploader-btn :upimgload="upimgload">
          <div  v-if="upimgload==true" class="upload_box">
             <img class="upload_img" src="@/assets/images/upload/upload.png"/>
              <p class="upload_title">上传附件</p>
          </div>
         
        </uploader-btn>
      </uploader-drop>
      <uploader-list></uploader-list>
    </slot>
  </div>
</template>

<script>
import Uploader from "simple-uploader.js";
import { kebabCase } from "./common/utils";
import UploaderBtn from "./btn.vue";
import UploaderDrop from "./drop.vue";
import UploaderUnsupport from "./unsupport.vue";
import UploaderList from "./list.vue";
import UploaderFiles from "./files.vue";
import UploaderFile from "./file.vue";

const COMPONENT_NAME = "uploaderimage";
const FILE_ADDED_EVENT = "fileAdded";
const FILES_ADDED_EVENT = "filesAdded";

export default {
  name: COMPONENT_NAME,
  props: {
    upimgload:{
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    autoStart: {
      type: Boolean,
      default: false
    },
    fileStatusText: {
      type: Object,
      default() {
        return {
          success: "success",
          error: "error",
          uploading: "uploading",
          paused: "paused",
          waiting: "waiting",
          // cmd5:'cmd5'
        };
      }
    }
  },
  data() {
    return {
      started: false,
      files: [],
      fileList: []
    };
  },
  methods: {
    uploadStart() {
      this.started = true;
    },
    fileAdded(file) {
      // this.$emit(kebabCase(FILE_ADDED_EVENT), file) //传参给父组件
      this.$emit("fileAdded", file);

      if (file.ignored) {
        // is ignored, filter it
        return false;
      }
      // this.computeMD5(file);
    },
    filesAdded(files, fileList) {
      this.$emit(kebabCase(FILES_ADDED_EVENT), files, fileList);
      if (files.ignored || fileList.ignored) {
        // is ignored, filter it
        return false;
      }
    },
    fileRemoved(file) {
      this.files = this.uploader.files;
      this.fileList = this.uploader.fileList;
    },
    filesSubmitted(files, fileList) {
      this.files = this.uploader.files;
      this.fileList = this.uploader.fileList;
      if (this.autoStart) {
        this.uploader.upload();
      }
    },
    allEvent(...args) {
      const name = args[0];
      if (name === FILE_ADDED_EVENT || name === FILES_ADDED_EVENT) {
        return;
      }
      args[0] = kebabCase(name);
      this.$emit.apply(this, args);
    }
  },
  created() {
    const uploader = new Uploader(this.options);
    this.uploader = uploader;
    // console.log(this.uploader)
    this.uploader.fileStatusText = this.fileStatusText;
    uploader.on("catchAll", this.allEvent);
    uploader.on("uploadStart", this.uploadStart);
    uploader.on(FILE_ADDED_EVENT, this.fileAdded);

    uploader.on(FILES_ADDED_EVENT, this.filesAdded);
    uploader.on("fileRemoved", this.fileRemoved);
    uploader.on("filesSubmitted", this.filesSubmitted);
  },
  destroyed() {
    const uploader = this.uploader;
    uploader.off("catchAll", this.allEvent);
    uploader.off("uploadStart", this.uploadStart);
    uploader.off(FILE_ADDED_EVENT, this.fileAdded);
    
    uploader.off(FILES_ADDED_EVENT, this.filesAdded);
    uploader.off("fileRemoved", this.fileRemoved);
    uploader.off("filesSubmitted", this.filesSubmitted);
    this.uploader = null;
  },
  components: {
    UploaderBtn,
    UploaderDrop,
    UploaderUnsupport,
    UploaderList,
    UploaderFiles,
    UploaderFile
  }
};
</script>

<style>
.uploader {
  position: relative;
}
</style>
