<template>
  <!-- 课程表 -->
  <div class="course-form flex-layout">
    <!-- 左侧日历表 start-->
    <el-calendar v-model="calendarValue">
      <template slot="dateCell" slot-scope="{ date, data }">
        <div class="inner" @click="selectDate(data.day)">
          <p :class="data.isSelected ? 'is-selected' : ''">
            {{ data.day.split("-")[2] }}
          </p>
          <div class="lesson" v-show="calendarList[data.day] && index < 3"
            v-for="(item,index) in calendarList[data.day]" :key="index"
            :class="[item.lessonsPattern === '1' ? 'lesson-async': '']">
            <p class="name wes">{{item.lessonsName}}</p>
          </div>
        </div>
      </template>
    </el-calendar>
    <!-- 左侧日历表 end-->
    <!-- 右侧课程列表 -->
    <div class="aside">
      <div class="summary flex-align-between">
        <p class="date">{{ day }}</p>
        <p class="total">
          共 <i>{{selectedList? selectedList.length : '0'}}</i> 节课
        </p>
      </div>
      <div class="course-card" v-for="(item, index) in selectedList" :key="index">
        <!-- Math.round(Math.random()*5) -->
        <div class="card csp flex-layout" @click.stop="toCourseDetail(item,index)">
          <div class="kind">
            <img class="course-type-image"
              :src="item.lessonsPattern === '0'? require('@/assets/images/course/syncourse.png') : require('@/assets/images/course/asyncourse.png')"
              alt="" />
          </div>
          <!-- <img :src="downloadURL + item.cover" alt="" :onerror="$store.state.course.defaultCourseCover[index%5]" class="course-cover"> -->
          <div>
            <p class="time" :class="{'living-time':item.mark == 2}">
              <!-- 同步课时间显示 -->
              <span v-if="item.pattern">{{item.beginTime}}-{{item.endTime}}</span>
              <!-- 异步课时间显示 -->
              <span v-else>{{item.beginTime}}</span>
              <!-- 课程状态 -->
              <span class="status " :class="[$store.state.course.courseMarkMap[item.mark].lessonStatus.class]">
                <!-- 同步课未开始 -->
                <img src="../../assets/images/home/unstart.png" alt=""
                  v-if="item.lessonsPattern == 0  && item.mark == 3">
                <!-- 同步课直播中 -->
                <i class="living-icon" v-if="item.lessonsPattern == 0  && item.mark == 2"></i>
                <img src="../../assets/images/home/living.png" alt=""
                  v-if="item.lessonsPattern == 0  && item.mark == 2">
                <!-- 同步课已结束 -->
                <img src="../../assets/images/home/finish.png" alt=""
                  v-if="item.lessonsPattern == 0  && item.mark == 5 || item.mark == 4">


                <!-- 异步课未开放 -->
                <img src="../../assets/images/home/unopen.png" alt=""
                  v-if="item.lessonsPattern == 1  && item.mark == 12">
                <!-- 异步课已开放 -->
                <img src="../../assets/images/home/start.png" alt=""
                  v-if="item.lessonsPattern == 1  && item.mark == 11">
              </span>
            </p>
            <p class="wes name">{{ item.lessonsName }}</p>
            <p class="wes teacher">
              <img :src="downloadURL+item.teacherAvatar" :onerror="$store.state.user.defaultAvatar" alt
                class="teacher-avatar" />
              主讲教师：{{ item.teacherName }}</p>
          </div>
        </div>
      </div>
      <!-- 暂无内容 -->
      <Empty :show="selectedList && selectedList.length === 0"></Empty>
    </div>

    <ToLiveMode :isVisible="downloadVisible" :liveLessonId="liveLessonId" top="125vh" @downClient="handleDownLoad">
    </ToLiveMode>
  </div>
</template>

<script>
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        downloadVisible: false,
        liveLessonId: 0,
        calendarList: {}, //课程表列表
        selectedList: [], //选中日期课节列表
        day: '', // 当前选择的日期
        calendarValue: new Date(), //课程表绑定value
      };
    },
    computed: {
      ...mapState({
        currentRole: state => state.user.role,
        projectId: state => state.user.currentProject.id
      })
    },
    watch: {
      currentRole(newVal, oldVal) {
        if (!newVal) return;
        this.getCalendarAll();
      }
    },
    methods: {
      handleDownLoad(data) {
        // console.log(data);
        this.downloadVisible = data.visible;
      },
      //点击选择日期
      selectDate(date) {
        this.day = date;
        // this.calendarValue = date;
        this.getScheduleOne(date);
      },
      //获取课程表(所有数据)
      async getCalendarAll() {
        let params = {
          isExpert: this.currentRole === 'expert' ? 1 : (this.currentRole === 'assistant' ? 2 : ''),
          projectId:this.projectId
        }
        // console.log(params)
        let resData = await this.$Api.Home.getSchedule(params);
        // console.log(resData.data);
        this.calendarList = resData.data ? resData.data : [];
      },
      //获取选中日期课程
      async getScheduleOne(day) {
        let params = {
          day: day,
          isExpert: this.currentRole === 'expert' ? 1 : (this.currentRole === 'assistant' ? 2 : null),
          projectId:this.projectId
        }
        // console.log(params);
        let resData = await this.$Api.Home.getScheduleOne(params);
        // console.log(resData);
        if (resData.data) {
          this.selectedList = resData.data;
          this.selectedList.forEach(item => {
            Vue.set(item, 'beginTime', this.dayjs(item.lessonsBeginTime * 1000).format('HH:mm'));
            Vue.set(item, 'endTime', this.dayjs(item.lessonsEndTime * 1000).format('HH:mm'));
            Vue.set(item, 'beginDate', item.lessonsBeginDate.split(' ')[0]);
            Vue.set(item, 'pattern', item.lessonsPattern === '0');
          });
        }
      },
      //日历表上个月/今天/下个月 点击事件
      clendarOption() {
        let pervMonth = document.querySelector(".el-calendar__button-group .el-button-group>button:nth-child(3)");
        let today = document.querySelector(".el-calendar__button-group .el-button-group>button:nth-child(2)");
        let nextMonth = document.querySelector(".el-calendar__button-group .el-button-group>button:nth-child(1)");

        pervMonth.textContent = '>';
        today.textContent = '';
        nextMonth.textContent = '<';

        let commonFun = (el, limit) => {
          el.addEventListener('click', () => {
            if (el === today) {
              let nowDay = this.dayjs().format('YYYY-MM-DD');
              this.selectDate(nowDay);
              return
            }
            let day = new Date(this.day)
            day = day.setDate(day.getDate() + limit)
            day = Common.formatTimestamp(day).split(' ')[0]
            this.day = day;
            this.calendarValue = day;
            this.selectDate(this.day);
          })
        }

        commonFun(pervMonth, 30)
        commonFun(today)
        commonFun(nextMonth, -30)
      },
      toCourseDetail(item, index) {
        let breadLength = this.$store.state.basics.breadList.length;
        this.$router.push({
          path: `/course/${item.courseProjectId}?&index=${index%5}`,
          query: {
            index: index % 5,
            breadNumber: breadLength + 1
          },
        })
      },
      //页面初始化
      init() {
        this.day = this.dayjs().format('YYYY-MM-DD');
        this.calendarValue = this.dayjs().format('YYYY-MM-DD');

        this.selectDate(this.day);
        this.getCalendarAll();
        this.clendarOption();
      },
    },
    mounted() {
      this.init();
    },
  };
</script>

<style lang="less">
  .course-form {
    // width: 1200px;
    margin-bottom: 146px;
    background-color: #fff;
    border-radius: 10px;

    .el-calendar {
      width: 70%;
      min-width: 490px;
      border-radius: 10px;
      transition: 1s all;
    }

    .el-calendar__header {
      position: relative;
      border: 0;
      padding: 26px 20px;

      .el-calendar__title {
        font-size: 12px;
        color: #8F95B3;
        position: absolute;
        top: 21px;
        left: 49px;
        z-index: 10;
      }

      .el-calendar__button-group {
        position: absolute;
        z-index: 999;
        top: 17px;
        left: 5px;

        .el-button {
          border: 0;
          color: #667AFF;
        }

        .el-button:focus,
        .el-button:hover {
          color: #8F95B3;
          background-color: transparent;
        }

        .el-button-group>.el-button:not(:first-child):not(:last-child) {
          width: 80px;
          background-color: transparent;
        }
      }


    }

    .el-calendar__body {
      padding: 0 12px 35px 16px;
    }

    .el-calendar-table {
      border-collapse: inherit;

      thead {
        border-radius: 5px;

        th {
          text-align: center;
          color: #8F95B3;
          font-size: 12px;

          &:first-child {
            border-top-left-radius: 10px;
          }

          &:last-child {
            border-top-right-radius: 10px;
          }
        }
      }
    }



    .el-calendar-table .el-calendar-day {
      text-align: right;
      height: 58px;
      padding: 0;
      transition: 1s all;
      overflow: hidden;

      .inner {
        padding: 3px 8px 0 6px;

        width: 100%;
        height: 100%;

        box-sizing: border-box;

        &>p {
          text-align: left;
          font-size: 12px;
          padding-bottom: 3px;
        }
      }
    }

    .el-calendar-table .current .el-calendar-day {
      .inner p {
        color: #8F95B3;
      }
    }

    .lesson {
      background-color: rgba(109, 212, 255, .32);
      color: #18A1DA;
      position: relative;
      text-align: left;
      padding-left: 8px;
      padding-right: 2px;
      font-size: 12px;
      margin-bottom: 4px;
      line-height: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background-color: #6DD4FF;
      }
    }

    .lesson-async {
      color: #EE9C0C;
      background-color: rgba(255, 203, 0, .2);

      &::before {
        background-color: #FFCB00;
      }
    }

    .is-selected {
      color: #333 !important;
    }

    .aside {
      max-height: 450px;
      width: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 10px;
      padding: 40px 9px 0px 0px;

      /*修改滚动条样式*/
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 2px;
      }

      &::-webkit-scrollbar-button {
        height: 15px;
      }

      &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 4px;
      }

      .summary {
        margin-bottom: 10px;

        .date {
          font-weight: 600;
          font-size: 16px;
          color: #333;
        }

        .total {
          font-size: 14px;
          font-weight: 600;
          color: #595959;

          i {
            color: #2373EC;
          }
        }
      }


      .course-card {
        margin-bottom: 27px;

        &:last-child {
          margin-bottom: 0;
        }

        .card {
          position: relative;
          padding: 0 6px 0 9px;
          border: 1px solid #F2F2F2;
          border-radius: 10px;
          // margin-bottom: 17px;

          .kind {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 0;
          }

          .course-cover {
            display: none;
            width: 81px;
            height: 64px;
            border-radius: 5px;
            margin-right: 9px;
          }

          .type {
            color: #2474ec;

            &::before {
              content: " ";
              display: inline-block;
              width: 4px;
              height: 19px;
              margin-right: 11px;
              background-color: #2474ec;
              vertical-align: middle;
            }
          }

          .type-async {
            color: #f86923;

            &::before {
              background-color: #2474ec;
            }
          }

          .time {
            font-size: 14px;
            // font-weight: bold;
            color: #999999;
            margin: 0px 0 8px;
            line-height: 35px;
            border-bottom: 1px solid rgba(160, 160, 160, .08);

            &::before {
              content: ' ';
              display: inline-block;
              margin: 0 5px;
              width: 6px;
              height: 6px;
              background: #999999;
              opacity: 0.8;
              border-radius: 50%;
              vertical-align: 2px;
            }

            &living-time {
              color: #508EF9;

              &::before {
                background: #508EF9;
              }
            }

            .status {
              display: inline-block;
              vertical-align: text-bottom;
              margin-left: 15px;
              padding: 0 6px;
              line-height: 14px;
              // height: 14px;
              color: #6e6e6e;
              background: #fefefe;
              border: 1px solid #d0d0d0;
            }

            .living {
              border: 0 !important;
              padding: 0 !important;
              vertical-align: 0 !important;
            }

            .living-icon {
              display: inline-block;
              background-image: url("~@/assets/images/home/vidicon.png");
              width: 14px;
              height: 10px;
              margin-right: 5px;
            }

            .open {
              border: 1px solid #4DDEA1;
            }
          }

          .name,
          .teacher {
            font-size: 13px;
            color: #999;
            max-width: 200px;
            padding: 0 7px;
          }

          .name {
            color: #666;
            margin: 8px 0;
            font-size: 14px;
            font-weight: 600;
          }

          .teacher {
            margin-bottom: 8px;
          }

          .teacher-avatar {
            width: 20px;
            height: 20px;
            vertical-align: -5px;
            margin: 0 7px 0 1px;
            border-radius: 50%;
          }

          .btn {
            display: block;
            margin: 0 auto;
            width: 120px;
            padding: 0 30px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #2373ec;
            line-height: 31px;
            border: 1px solid #2373ec;
            border-radius: 5px;
          }

          .btn-blue {
            color: #fff;
            background: linear-gradient(90deg, #2272ec, #3a7bf6);
            box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
          }

          .btn-grey {
            border: 1px solid #c0c0c0;
            color: #fff;
            background: #c0c0c0;
            box-shadow: 0px 5px 13px 0px rgba(137, 137, 137, 0.28);
          }
        }
      }
    }

    @media screen and (min-width: 1250px) {
      .el-calendar {
        min-width: 600px;
      }

      .el-calendar__body {
        padding: 0 15px 35px 19px;
      }

      .el-calendar-table .el-calendar-day {
        height: 77px;

        .inner {
          padding: 3px 15px 0 6px;
        }
      }

      .aside {
        max-height: 575px;

        .course-card .card .course-cover {
          display: block;
        }

        .course-card .card .name {
          max-width: 230px;
        }
      }
    }
  }
</style>