// import Vue from 'vue'
// import VueRouter from 'vue-router'
// Vue.use(VueRouter)
//首页
import HomeRouter from "./router/home-router";
//能力体系
import AbilityRouter from "./router/ability-router";
//智慧学堂
import SchoolRouter from "./router/school-router";
//资源中心
import RescourcesRouter from "./router/resources-router";
//教学督导
// import SupserviseRouter from "./router/supervise/supervise-router";
//登录
import Login from "./router/login-router";
//个人中心
import My from "./router/my-router";
//教学管理
// import TeachManage from "./router/center/teach-manage";
//我的课程
// import Course from "./router/center/course-router";
//我的任务
// import Task from "./router/center/task-router";
//综合评价
// import Evaluate from "./router/center/evaluate-router";
//上传
import Uploader from "./router/uploader-router";
//问卷
import Questionnaire from "@/router/questionnaire-router";
//领导
import Leader from "@/router/leader-router";
// 中心首页
import Center from "@/router/center/center-index"
// 新活动
import Newactve from "./router/active-router"
// 测试页面
import Template from './router/test-router'
// 学分页面
import Score from './router/score-router'
// 管理员
import Manager from './router/manager'

// 数据大屏
import dataScreen from './router/dataScreen-router'

export default [
  ...HomeRouter,
  ...AbilityRouter,
  ...SchoolRouter,
  ...RescourcesRouter,
  // ...SupserviseRouter,
  ...Login,
  ...My,
  // ...TeachManage,
  ...Uploader,
  // ...Course,
  // ...Task,
  ...Questionnaire,
  // ...Evaluate,
  ...Leader,

  ...Center,
  ...Newactve,
  ...Template,
  //数据大屏
  ...dataScreen,
  ...Score,
  ...Manager
]