import { get, put, patch, post, fetch, del} from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

const OssUpload = {
  //报名情况列表
  getQueryToken(data) {
    return get("/fu/aliyun/queryToken", data);
  },
  getCheck(data) {
    return post("/fu/aliyun/check", data);
  },
  postCheck(data) {
    return post("/fu/aliyun/chunk", data);
  },
  postMerge(data) {
    return post("/fu/aliyun/merge", data);
  }
};

// 默认全部倒出
export default OssUpload;