import {
  get,
  put,
  patch,
  post,
  fetch,
  del
} from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
const Home = {
  //登录
  login(data) {
    return post("/auth/user/login", data);
  },
  // 登出
  logout(data) {
    return del("/auth/user/logout", data);
  },
  //个人信息
  userInfo(data) {
    return get("/auth/user/info", data);
  },
  //查询用户身份
  getroles(data) {
    return get("/auth/user/roles", data);
  },
  // 第三方登录（银川）
  loginThird(data) {
    return get("/auth/user/third/login", data)
  },
  // 第三方查询绑定列表（银川）
  getBindList(data) {
    return get("/auth/user/info/checkBind", data)
  },
  // 第三方解除绑定（银川）
  relieveBind(data) {
    return del("/auth/user/info/relieveBind", data)
  },
  getInternetCourseList(data) {
    return get("/auth/third/queryThirdInfoByProjectId", data)
  },
  // 检查是否为第三方接口 （云品平台）
  checkHasThirdUser(data) {
    return get("/auth/third/checkHasThirdUser", data)
  },
  // 精准教学
  getAuthKey(data) {
    return get("/auth/third/getAuthKey", data)
  },
  //查询用户参与项目
  getProjectList(data) {
    return get("/learn/overview/user_projects", data);
  },
  //查询进度统计信息
  getExtentInfo(data) {
    return get("/learn/overview/user_project_total", data);
  },
  //查询用户课程计划
  getUserDaily(data) {
    return get("/learn/overview/user_daily", data)
  },
  getSchedule(data) {
    return get("/learn/overview/user_schedule", data)
  },
  getScheduleOne(data) {
    return get("/learn/overview/user_schedule_one", data)
  },
  // 查询用户所在一集项目
  getTopLevel(data) {
    return get("/auth/project/getTopLevelProject", data);
  },
  getTrainPlanInfo(data) {
    return fetch("/course/item/detail/", data)
  },
  getDialogInfo(data) {
    return get("/course/teach/course/checkExpertLive", data);
  },
  queryrelation(data) {
    return get("/fu/center/query_relation", data);
  },
  fileComplete(data) {
    // return post("/fu/aliyun/merge",data);
    // return post("/fu/front/upload/mergeFile",data);
    return post("/fu/front/upload/mergeFile_v1", data);
  },
  // 查询领导页四个饼图 
  getPieData(data) {
    return get("/course/statistics/count/getPieStatistics", data);
  },
  // 查询领导页能力图谱套餐
  getAbilityByAdmin(data) {
    return get("/score/scoreAbility/getAbilitySuitByAdmin", data);
  },
  // 查询领导页能力图谱雷达图
  getAbilityRadar(data) {
    return get("/score/scoreAbility/getRoleAbility", data);
  },
  // 查询领导页面课程情况
  getCourseChart(data) {
    return get("/course/statistics/count/getCourseChart", data);
  },
  // 查询领导页面作业情况
  getHomeWorkChart(data) {
    return get("/course/statistics/count/getHomeworkChart", data);
  },
  // 查询领导页面活动情况
  getActivityChart(data) {
    return get("/course/statistics/count/getActivityChart", data);
  },
  // 查询领导页面 平均分
  getCountAvg(data) {
    return fetch("/course/statistics/count/avg/", data);
  },
  // 查询通知公告
  getNewsListByProjectId(data) {
    return get("/course/teach/news/getNewsListByProjectId", data);
  },
  // 查询通知公告详情
  getNewsOne(data) {
    return get("/news/one", data);
  },
  // 获取查询筛选条件
  userProjectYears(data) {
    return get("/learn/project/userProjectYears", data);
  },
  // 用户项目列表
  userProjects(data) {
    return get("/learn/project/userProjects", data);
  },
  //用户项目信息
  getuserInfo(data) {
    return get("/learn/project/userInfo", data);
  },
  // 获取当前项目信息
  getProjectInfo(data) {
    return fetch("/course/item/detail/", data);
  },
  // 获取待办事项(专家)
  getExpertUndo(data) {
    return get("/learn/project/expertUndo", data)
  },
  // 获取代办事项(参训教师)
  getTeacherUndo(data) {
    return get("/learn/project/teacherUndo", data)
  },
  // 测试
  test(data) {
    return post("/quest/import/test", data)
  },
  // 获取oss文件下载地址
  getDownloadUrl(data) {
    return get("/fu/front/upload/getDownloadUrl", data)
  },
  // 获取项目下配置权限的 用户列表
  getPermissionConf(data) {
    return get("/learn/project/getPermissionConf", data)
  },
  // 获取应用中心列表
  getAppList(data) {
    return get("/learn/project/appList", data)
  },
  // 获取应用中心跳转参数
  getldentifying(data) {
    return post("/auth/third/getIdentifying", data)
  },
  // 获取乐享跳转地址 /project/getGoLxUrl
  getGoLxUrl(data){
    return get("/learn/project/getGoLxUrl",data)
  }
};

// 默认全部倒出
export default Home;