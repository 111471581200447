// 能力报告
export default [{
        path: "/report/personal",
        name: "ability_report_personal",
        component: resolve => require(['../../views/ability/report_personal.vue'], resolve),
        meta: {
            title: "个人能力报告",
            bread: true,
            keepAlive: false
        }
    },
    {
        path: "/report/improve",
        name: "ability_report_improve",
        component: resolve => require(['../../views/ability/report_improve'], resolve),
        meta: {
            title: "提升能力",
            bread: true,
            keepAlive: false
        }
    },
    // {
    //     path: "/ability_improve",
    //     name: "ability_improve",
    //     component: resolve => require(['../views/ability/ability_improvement'], resolve),
    //     meta: {
    //         title: "提升能力copy",
    //         keepAlive: true
    //     }
    // }
];