<template>
    <h3 class="project-title">
        <img :src="srcUrl" alt="" class="project-icon">
        {{this.currentProject.name}}
    </h3>
</template>

<script>
    import {
        mapState
    } from "vuex";
    export default {
        props: {
            iconUrl: {
                type: String,
                default: 'images/admin/project-icon.png'
            }
        },
        data() {
            return {
                srcUrl: require(`@/assets/images/admin/project-icon.png`)
            };
        },
        computed: {
            ...mapState({
                currentProject: state => state.user.currentProject,
                count(state) {
                    console.log(state);
                }
            })
        },
        watch: {
            'iconUrl': {
                handler(newVal, oldVal) {
                    this.srcUrl = require(`@/assets/${this.iconUrl}`);
                },
            }
        },
        mounted() {
            // this.srcUrl = require(`@/assets/${this.iconUrl}`);
        },
    }
</script>

<style lang="less" scoped>
    .project-title {
        margin-bottom: 30px;
        font-size: 15px;
        font-weight: bold;

        .project-icon {
            display: inline-block;
            // background-image: url("~@/assets/images/admin/project-icon.png");
            background-size: 100% 100%;
            height: 19px;
            width: 18px;
            margin-right: 12px;
            vertical-align: -3px;
        }
    }
</style>