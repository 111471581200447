// 直播
const state = {
  liveUrl: "",
  pdfUrl:'',
  pdfName:'',
};

const mutations = {
  getLiveUrl(state, res) {
    state.liveUrl = res;
  },
  getPdfUrl(state, res) {
    state.pdfUrl = res;
  },
  getPdfName(state,res) {
    state.pdfName = res;
  }
};

export default {
  state,
  mutations
};
