//讨论回复框打开状态
const state = {
    replyArea: -1,
    defaultCommentCover:[ //默认课程cover图片 列表页
      `this.src="${require("@/assets/images/question/cover1.png")}"`,
      `this.src="${require("@/assets/images/question/cover2.png")}"`,
      `this.src="${require("@/assets/images/question/cover3.png")}"`,
      `this.src="${require("@/assets/images/question/cover4.png")}"`,
      `this.src="${require("@/assets/images/question/cover5.png")}"`,
      `this.src="${require("@/assets/images/question/cover6.png")}"`,
   ],
  };
  
  const mutations = {
    getReplyArea(state, res) {
      state.replyArea = res;
    },
  };
  
  export default {
    state,
    mutations
  };
  