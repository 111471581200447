<template>
<!-- 新疆园长培训 弹窗 -->
   <div class="xj_dialog">
    <el-dialog
        title=""
        :visible.sync="show"
        width="600px"
        center
        top="6vh"
        :modal="true"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        :append-to-bod="false"
        @close="closeDialog"
        >
        <!-- :show-close="false" -->
        <img src="@/assets/images/home/xj-dialog.jpg" alt="">
    </el-dialog>
    <span class="close"></span>
   </div>
</template>

<script>
export default {
    data() {
        return {
            show: false, //是否展示弹窗
            isTeacher: false, //是否有老师身份
            isXinJiang: false, //是否参加了新疆项目
        }
    },
    methods: {
        async getTopLevel() {
            let resData = await this.$Api.Home.getProjectList();
            // console.log(resData);
            if(!resData.data) return;
            resData.data.forEach(element => {
                if(element.id == 93) {
                // if(element.id == 82) {
                    this.isXinJiang = true;
                }
            });
            // console.log(this.isXinJiang)
            
            // console.log(this.isXinJiang && this.isTeacher)
            if(this.isXinJiang && this.isTeacher) this.show = true;

        },
        checkRole() {
            // let roleList = JSON.parse( sessionStorage.getItem('rolelist'));
            let roleList = this.$store.state.user.userRoleList
            roleList.forEach(element => {
                if(element.value === 'teacher') {
                    this.isTeacher = true;
                }
            })
            //  console.log(this.isTeacher)
        },
        closeDialog() {
            sessionStorage.setItem('xjDialog',true);
        },
        init() {
            let isDialog = Boolean(sessionStorage.getItem('xjDialog'));
            if(!isDialog) {
                this.checkRole();
                this.getTopLevel();
            }
        },

    },
    mounted() {
        this.init();
    },
    activated() {
        this.init();
    },
    deactivated() {
        this.show = false;
        this.isXinJiang = false;
        this.isTeacher = false;
    },
}
</script>

<style lang="less" scoped>
.xj_dialog {
    position: relative;
    img {
        // max-width: 100%;
        // width: 100%;
        // height: 100%;
        margin:0 auto;
        display: block;
        // object-fit: contain;
    }
    /deep/ .el-dialog {
        margin: 0 auto 20px;
    }

    /deep/ .el-dialog__header {
        padding: 0;
        
        .el-dialog__headerbtn {
            top: 0;
            right: -40px;
            padding: 3px;
            border-radius: 50%;
            border: 1px solid #fff;
            
            &:hover {
                background-color: #909399;
                .el-dialog__close{
                    color: #fff;
                }
            }

            .el-dialog__close {
                color: #fff;
                font-size: 20px;
                vertical-align: -1px;
            }
        }
    }

    /deep/ .el-dialog--center .el-dialog__body {
        padding: 20px 0;
    }

    .close {
        display: block;
        width: 10px;
        height: 10px;
        background-color: #fff;
    }
}

</style>