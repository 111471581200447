import Login from "./Login";
import My from "./My";
import Home from "./Home";
import Project from "./Project";
import Course from "./Course";
import Form from './Form'
import DST from './DST'
import Resources from './Resources'
import Activity from './activity'
import OssUpload from './OssUpload'
import Score from './score'
import dataScreen from './dataScreen'
import wjx from './wjx'
import space from './space'
import kimi from './Kimi'

const Api = {
  Login,
  My,
  Home,
  Project,
  Course,
  Form,
  DST,
  Resources,
  Activity,
  OssUpload,
  Score,
  dataScreen,
  wjx,
  space,
  kimi
};

// 导出
export default Api;
