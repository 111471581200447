import {
  get,
  put,
  patch,
  post,
  fetch,
  del,
  Delete,
  formDataPost
} from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
const project = {
  //学校空间-标题数据
  getschoolTitle(data) {
    return get("/score/stat/school/title", data)
  },
  //学校空间-项目总体情况
  getprojectUserCount(data) {
    return get("/score/stat/school/projectUserCount", data)
  },
  //学校空间-项目热门名字
  getprojectUserName(data) {
    return get("/score/stat/school/projectUserName", data)
  },
  //学校空间-项目学习数据
  getprojectStudyInfo(data) {
    return get("/score/stat/school/projectStudyInfo", data)
  },
  //学校空间-各时段作业人数统计
  gettimeQuest(data) {
    return get("/score/stat/school/timeQuest", data)
  },
  //学校空间-各时段学习情况
  gettimeStudy(data) {
    return get("/score/stat/school/timeStudy", data)
  },
  //学校空间-参训率情况
  getparticipationRate(data) {
    return get("/score/stat/school/participationRate", data)
  },
  // 旗县空间-学校参训人数
  getSchoolUserCount(data) {
    return get("/score/stat/area/schoolUserCount", data)
  },
  // 旗县空间-项目参训人数
  getProjectUserCount(data) {
    return get("/score/stat/area/projectUserCount", data)
  },
  // 旗县空间-各时段任务提交（作业）情况
  getTimeQuest(data) {
    return get("/score/stat/area/timeQuest", data)
  },
  // 旗县空间-各时段学习情况
  getTimeStudy(data) {
    return get("/score/stat/area/timeStudy", data)
  },
  // 旗县空间-获取中部总数据
  getTotalTitle(data) {
    return get("/score/stat/area/title", data)
  },
  // 旗县空间-各项目学习时长
  getStudyAll(data) {
    return get("/score/stat/area/studyAll", data)
  },
  // 旗县空间-项目成果 (作业和活动提交数)
  getAchievement(data) {
    return get("/score/stat/area/achievement", data)
  },
  // 旗县空间-获取areaOrgId
  getAreaId(data) {
    return get("/score/stat/area/getAreaId", data)
  },
  //盟市空间-获取area OrgId
  getCityId(data) {
    return get("/score/stat/city/getCityId", data)
  },
   //盟市空间-获取 盟市下的总数据
   getCityTitle(data) {
    return get("/score/stat/city/getCityTitle", data)
  },
   //盟市空间-获取 盟市下 各项目成果数量
   getCityAchievement(data) {
    return get("/score/stat/city/getCityAchievement", data)
  },
  //盟市空间-获取 盟市下 学习时长
  getCityStudy(data) {
    return get("/score/stat/city/getCityStudy", data)
  },
  //市空间-获取 盟市下 项目基本数据
  getCityProject(data) {
    return get("/score/stat/city/getCityProject", data)
  },
  //盟市空间-获取 盟市下 旗县项目数量分布
  getAreaProject(data) {
    return get("/score/stat/city/getAreaProject", data)
  },


};

// 默认全部倒出
export default project;