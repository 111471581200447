
 //综合评价
 const EvaluateDetail = () =>import ("../../views/evaluate/evaluate_detail.vue"); //能力详情
 const EvaluateStudents = () =>import ("../../views/evaluate/component/evaluate_student.vue"); //能力详情
 const EvaluateTeacher = () =>import ("../../views/evaluate/component/evaluate_teacher.vue"); //能力详情
 
 export default [
    {
        path: '/evaluateDetail',
        name: 'evaluateDetail',
        component: EvaluateDetail,
        meta: {
            title: '评价详情',
            keepAlive: true
        },
        children:[
            {
                path: 'student/:id',
                name: 'evaluateStudents',
                component: EvaluateStudents,
                meta: {
                    title: '评价详情学生',
                    keepAlive: true
                },
            },
            {
                path: 'teacher/:id',
                name: 'evaluateTeacher',
                component: EvaluateTeacher,
                meta: {
                    title: '评价详情老师',
                    keepAlive: true
                },
            }
        ]
    }, 
   ];