<template>
  <!-- 无内容 -->
    <div class="nothing-box flex-align" v-if="show">
      <div class="nothing-wrap">
        <img src="@/assets/images/nothing.png" alt="">
        <p>{{text}}</p>
      </div>
    </div>
</template>

<script>
export default {
    props:{
        text:{
            type:String,
            default:'暂无内容'
        },
        show:{
            type:Boolean,
            default:false
        }
    }
    
    
}
</script>

<style lang="less" scoped>
.nothing-box {
    height: 90%;
    .nothing-wrap {
      margin: 55px auto;
    }

    img {
      max-width: 100%;
      vertical-align: middle;
    }
    p {
      text-align: center;
      margin-top: -68px;
      font-size: 16px;
      color: #999;
    }
}
</style>