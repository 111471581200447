const getDefaultState = () => {
  return {
    isRefresh: false,
    isCreate: false,
    activeModuleHref: undefined,
    hasMain: false, // 当前项目是否有项目主页
    discussInfo: {}, // 云端答疑讨论组信息（名字+期）
    breadList: [{ // 面包屑导航
      name: '首页',
      path: '/'
    }, {
      name: '研修空间',
      path: '/home'
    }],
    myActiveModule: 0, //个人中心左侧导航栏选中状态
  }
}

const state = getDefaultState();

const mutations = {
  getRefreshState(state, res) {
    state.isRefresh = res
  },
  getCreateState(state, res) {
    state.isCreate = res
  },
  getActiveModuleHref(state, res) {
    state.activeModuleHref = res
  },
  getHasMain(state, res) {
    state.hasMain = res
  },
  getDiscussInfo(state, res) {
    state.discussInfo = res;
  },
  breadListAdd(state, obj) {
    let length = state.breadList.length
    // if (obj.name != state.breadList[length - 1].name) {
    state.breadList.push(obj);
    // }
  },
  breadListRemove(state, num) {
    state.breadList = state.breadList.slice(0, num);
  },
  breadPageNameChange(state, name) {
    let length = state.breadList.length
    state.breadList[length - 1].name = name;
    if (name.length > 22) {
      state.breadList[length - 1].name = `${name.slice(0, 22)}...`
    }
  },
  getMyActiveModule(state, res) {
    state.myActiveModule = res;
  },
  resetModuleState(state, res) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  mutations
};