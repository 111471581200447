<template>
    <div class="dialog">
        <div class="tips-dialog-wrapper">
            <el-dialog title="上课提醒" :visible.sync="centerDialogVisible" width="35%" center :modal="false"
                :close-on-click-modal="false" :modal-append-to-body="false" :append-to-bod="false"
                :class="{'show':centerDialogVisible}">
                <span>{{userInfo.name}}老师，您的《{{courseInfo.name}}》课程于
                    {{ this.handleTimestamp(courseInfo.lessonsBeginTime)}}开始上课，快快进入直播教室吧~</span>
                <span slot="footer" class="dialog-footer">
                    <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
                    <el-button type="primary" @click="golive">进入直播</el-button>
                </span>
            </el-dialog>
        </div>
        <ToLiveMode :isVisible="downloadVisible" :liveLessonId="courseInfo.id" top="15vh"
            :classType="courseInfo.classType" @downClient="handleDownLoad">
        </ToLiveMode>
    </div>
</template>

<script>
    import {
        mapMutations,
        mapState
    } from "vuex";
    export default {
        data() {
            return {
                centerDialogVisible: false,
                role: '',
                userInfo: {},
                courseInfo: {},
                downloadVisible: false,
            };
        },
        watch: {
            role(newVal, oldVal) {
                //console.log('newVal',newVal,'oldVal',oldVal)
                this.role = newVal
            },
        },
        computed: {
            ...mapState({
                currentRole: state => state.role.role,
            })
        },
        methods: {
            ...mapMutations(["getUrl"]),
            handleDownLoad(data) {
                // console.log(data);
                this.downloadVisible = data.visible;
            },
            init() {
                // this.role = sessionStorage.getItem('role')
                this.role = this.$store.state.user.role
                // this.userInfo = JSON.parse(sessionStorage.getItem('userinfo'))
                this.userInfo = this.$store.state.user.userInfo;
                this.centerDialogVisible = false;
                this.showDialog();
            },
            async showDialog() {
                let isDialog = Boolean(sessionStorage.getItem('isDialog'))
                let resData = await this.$Api.Home.getDialogInfo();
                // console.log(resData)
                if (resData.data.name) {
                    this.courseInfo = resData.data
                    if (this.role === 'expert' && !isDialog) {
                        this.centerDialogVisible = true;
                        sessionStorage.isDialog = true;
                    }
                }

            },
            async golive() {
                // classin直播课客户端内
                if (window.qt && this.courseInfo.classType == 5) {
                    window.nativeBridge.initConfig.connect(function (config) {
                        console.log('config',config);
                        initConfig(config);
                    });
                    let params = {
                        lessonId: this.courseInfo.id
                    }
                    let resData = await this.$Api.Course.getClassInLivingInfo(params)
                    // let school = 632500; // 正式
                    let school = 101134; // 测试
                    let zone = 86
                    let classtype = 2
                    let telephone = resData.data.phone;
                    let course = resData.data.courseId;
                    let lesson = resData.data.lessonId;
                    window.nativeBridge.openClassroom(zone, telephone, school, course, lesson, classtype)
                    return;
                }
                this.downloadVisible = true;
            },
            //处理时间戳
            handleTimestamp(time) {
                let _time = time
                _time = Common.formatTimestamp(time);
                _time = _time.split(' ')
                return _time[1];
            },
        },
        activated() {
            this.init();
        },
        mounted() {
            this.init();
        },
    }
</script>

<style lang="less" scoped>
    .dialog .tips-dialog-wrapper {
        /deep/.el-dialog__wrapper {
            position: static;
            width: fit-content;
        }

        /deep/.el-dialog {
            position: fixed;
            bottom: 10px;
            right: -450px;
            margin: 0;
            border-radius: 4px;
            overflow: hidden;
        }

        .show /deep/.el-dialog {
            right: 0px;
            transition: 1s all ease;
        }

        /deep/.el-dialog__header {
            background-image: url("../../assets/images/teach/create-title.png");
            background-size: cover;
            padding: 0px;
            line-height: 40px;


            .el-dialog__title {
                color: #fff;
                font-size: 16px;
            }

            .el-dialog__headerbtn {
                top: 10px;
            }

            .el-icon-close:before {
                color: #fff;
            }
        }

        /deep/.el-dialog__footer {
            .el-button--primary {
                background-color: #508EF9;
                border-color: #508EF9;
                padding: 0 25px;
                line-height: 30px;
            }
        }

        /deep/ .el-dialog__body {
            color: #666;
            padding: 25px 30px 30px;
        }
    }
</style>