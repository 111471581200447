<template>
  <!-- 资源圈等任意上传功能 -->
  <!-- @file-change="handleFileChange" -->
  <div class="upload-file">
     <!-- 本地断点续传 -->
    <el-drawer v-if="!BreakPointUpload" title="" :visible.sync="isOpenDrawer" @opened="openDialogAuto" :before-close="handleClose">
      <upimg ref="upimg" v-if="isOpenDrawer" :upimgload="true" @closeMain="closeMain"></upimg>
    </el-drawer>
    <!-- 阿里云上传 -->
    <el-drawer v-if="BreakPointUpload" title="" :visible.sync="isOpenDrawer" :before-close="handleClose">
        <ossUploader v-if="isOpenDrawer"  @closeMain="closeMain" :sourceType=7></ossUploader>
    </el-drawer>
  </div>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        upimgload: false,
        isOpenDrawer: false, // 侧边栏是否呈开启状态
        fileList: [], //文件列表
        fileMD5List: [], //文件md5列表
      }
    },
    watch: {
      show(newVal, oldVal) {
        if (newVal) {
          this.isOpenDrawer = newVal
        }
      },
      isOpenDrawer(newVal, oldVal) {
        this.handleFileChange();
      }
    },
    computed: {},
    methods: {
      // 关闭右侧上传抽屉提示弹窗
        handleClose(done) {
            this.$confirm('确认关闭？')
            .then(_ => {
                done();
            })
            .catch(_ => {});
        },
      // 自动打开浏览器弹窗
      openDialogAuto() {
        this.$refs.upimg.$el.querySelector("input").click();
      },
      // 文件上传成功后的回调
      closeMain(val) {
        this.fileMD5List.push(val.filemd5);
        let file = {
          filemd5: this.downloadURL + val.filemd5,
          name: val.name
        };
        console.log(file)
        this.fileList.push(file);
        // console.log(this.fileList);
        this.handleFileChange();
      },
      handleFileChange() {
        this.$emit('uploadFile', {
          fileList: this.fileList,
          fileMD5List: this.fileMD5List,
        });
      }
    },
    deactivated() {
      this.fileList = []
      this.fileMD5List = []
    },
  }
</script>

<style lang="less" scoped></style>