//资源
const state = {
  defaultResourceCoverMap: {
    'mp3': `this.src="${require("@/assets/images/resource/mp3.png")}"`,
    'mov': `this.src="${require("@/assets/images/resource/mp4.png")}"`,
    'mp4': `this.src="${require("@/assets/images/resource/mp4.png")}"`,
    'pdf': `this.src="${require("@/assets/images/resource/pdf.png")}"`,
    'ppt': `this.src="${require("@/assets/images/resource/ppt.png")}"`,
    'pptx': `this.src="${require("@/assets/images/resource/ppt.png")}"`,
    'doc': `this.src="${require("@/assets/images/resource/word.png")}"`,
    'docx': `this.src="${require("@/assets/images/resource/word.png")}"`,
    'xls': `this.src="${require("@/assets/images/resource/ex.png")}"`,
    'xlsx': `this.src="${require("@/assets/images/resource/ex.png")}"`,
  },
  unknowResourceCover: `this.src="${require("@/assets/images/default-cover.png")}"`
};

const mutations = {};

export default {
  state,
  mutations
};