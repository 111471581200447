import {
    get,
    put,
    patch,
    post
} from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
const Login = {
    //用户登录
    login(data) {
        return post("/auth/user/pcLogin", data);
    },
    //通过忘记密码 修改密码
    getFix_pass(data) {
        return post("/auth/user/phone/fix_pwd", data);
    },
    //修改手机号
    getFix_phone(data) {
        return put("/auth/user/fix_phone", data);
    },
    //发送手机验证码
    getVerifyCode(data) {
        return post("/auth/user/phone/send_code", data);
    },
    //验证手机验证码
    checkCode(data) {
        return post("/auth/user/phone/check", data);
    },
    // 用户手机验证码登录
    loginByPhone(data) {
        return post("/auth/user/loginByPhone", data);
    },
    // 验证用户是否为管理员
    isAdmin(data) {
        return get("/course/teach/comment/isAdmin", data);
    },
    // 获取真实姓名列表
    getRealNameList(data) {
        return get("/auth/user/queryMember", data);
    },
    // 获取多账户选择后的账户token
    getMemberToken(data) {
        return post("/auth/user/queryMemberToken", data);
    },
    // 获取图形验证码
    getCpatcha(data) {
        return get("/auth/user/captcha", data);
    },
    // 测试模板
    template(data) {
        return post("/python/zitie/test", data)
    },
    // 首师优字免密登录
    thirdOrgLogin(data) {
        return post("/auth/user/third/thirdOrgLogin", data)
    },
    template(data) {
        return post("/python/zitie/test", data)
    },
    // 是否是虚拟账号
    isVirtua(data) {
        return post("/auth/virtual/login/isVirtual", data)
    },
    // 虚拟账号登录
    vpclogin(data) {
        return post("/auth/virtual/login/vpclogin", data)
    },
    // 虚拟账号获取信息选项
    getOption(data) {
        return get("/auth/virtual/login/getOption", data)
    },
    // 虚拟账号完善信息 
    improveInfo(data) {
        return post("/auth/virtual/login/improveInfo", data)
    },
    // 虚拟账号修改密码 
    vchangePwd(data) {
        return post("/auth/virtual/login/changePwd", data)
    }
};

// 默认全部倒出
export default Login;