const events = [
  "fileProgress",
  "fileSuccess",
  "fileComplete",
  "fileError",
  "fileAdded",
  "filesAdded"
];

export default events;
