const Uploader = () => import("../views/uploader/uploader.vue");
const OssUpload = () => import("../views/uploader/ossupload.vue");
const Oss = () => import("../views/uploader/oss.vue");
export default [
  {
    path: "/uploader",
    name: "uploader",
    component: Uploader,
    meta: {
      title: "上传",
      keepAlive: true
    }
  },
  {
    path: "/ossupload",
    name: "ossupload",
    component: OssUpload,
    meta: {
      title: "上传",
      keepAlive: false
    }
  },{
    path: "/oss",
    name: "oss",
    component: Oss,
    meta: {
      title: "上传",
      keepAlive: false
    }
  },
];
