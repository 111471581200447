<template>
  <!-- 选择学科 组件 -->
  <el-select v-model="selected" placeholder="请选择课程学科" @change="changeSubject" clearable>
    <el-option v-for="(item,index) in subjectList" :key="index" :label="item.name" :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
  export default {
    props: {
      // 是否是编辑
      isEdit: {
        type: Boolean,
        default: false,
      },
      // 回显值
      editSelected: {
        type: Number,
      }
    },
    data() {
      return {
        selected: '',
        subjectList: []
      }
    },
    computed: {},
    watch: {
      editSelected(newVal, oldVal) {
        if (!newVal) return
        this.selected = this.editSelected;
      }
    },
    methods: {
      // 获取学科列表
      async getSubjectList() {
        let resData = await this.$Api.Course.SubjectList();
        this.subjectList = resData.data;
        // this.selected = resData.data[0].id
        if (!this.isEdit) this.$emit('subject', this.selected);
      },
      // 切换学科
      changeSubject(val) {
        this.$emit('subject', val);
      },
    },
    mounted() {
      this.getSubjectList();
    },
  }
</script>

<style>

</style>