<template>
    <!-- 图片预览组件 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="previewImages" :initialIndex="current">
    </el-image-viewer>
</template>

<script>
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer.vue'
    export default {
        components: {
            ElImageViewer
        },
        data() {
            return {
                showViewer: false,
                previewImages: [],
                current: 0,
                dom: Element,
            }
        },
        watch: {
            showViewer(newVal) {
                if (newVal) {
                    this.$nextTick(() => {
                        document.body.setAttribute('style', 'overflow:hidden');
                        this.dom = document.getElementsByClassName('el-image-viewer__mask');
                        this.dom[0].addEventListener('click', this.closeViewer, false)
                    })
                }

                if (!newVal) {
                    document.body.removeAttribute('style')
                    if (this.dom) {
                        this.dom[0].removeEventListener('click', this.closeViewer);
                    }
                }
            }
        },
        methods: {
            closeViewer() {
                this.showViewer = false;
            }
        }
    }
</script>

<style lang="less" scoped>
    .el-image-viewer__close {
        color: #fff;
    }
</style>