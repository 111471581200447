<template>
    <el-breadcrumb separator="/" class="bread-crumb">
        <el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadList" :key="index">
            {{item.name}}
        </el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    export default {
        watch: {
            $route: {
                handler(to, from) {
                    // console.log('bread', to)
                    // console.log('bread', from)
                    if (to.name) {
                        if (to.name == 'mainPage') {
                            this.$store.commit('breadListRemove', 1);
                            this.$store.commit('breadListAdd', {
                                name: '项目主页',
                                path: `/main/${this.projectId}`
                            })
                        }
                        if (to.name == 'schedule' || to.name == 'leader_home') {
                            this.$store.commit('breadListRemove', 1);
                            this.$store.commit('breadListAdd', {
                                name: '研修空间',
                                path: to.name == 'schedule' ? '/home/schedule' : '/home/leader_home'
                            })
                        }
                    }
                    if (from && !from.name) {
                        this.breadList = this.vuexBreadList;
                        return;
                    }
                    this.getBreadcrumb();
                },
                // immediate:true,
                deep: true
            },
        },
        data() {
            return {
                breadList: [], //路由集合
            }
        },
        created() {},
        computed: {
            ...mapState({
                projectId: state => state.user.currentProject.id,
                vuexBreadList: state => state.basics.breadList,
            })
        },
        methods: {
            getBreadcrumb() {
                let breadNumber = this.$route.query.breadNumber || 2;
                let breadLength = this.vuexBreadList.length;
                let curName = this.$route.meta.title;
                let curPath = this.$route.fullPath;
                let newBread = {
                    name: curName,
                    path: curPath
                };
                let ishome = curName == '中心首页';
                if (breadNumber === 2) { //点击一级菜单
                    this.$store.commit('breadListRemove', 2); //初始化，只有首页面包屑按钮
                    if (!ishome) //如果不是首页
                        this.$store.commit('breadListAdd', newBread); //当前页面添加到breadlist集合
                } else if (breadLength < breadNumber) { //如果不是一级导航，并且breadlist集合个数小于目前层级
                    this.$store.commit('breadListAdd', newBread); //要把当前路由添加到breadlist集合
                } else if (breadLength == breadNumber) { // 如果breadlist集合个数等于目前层级
                    this.$store.commit('breadListRemove', parseInt(breadLength - 1)) //先删除最后一个层级
                    this.$store.commit('breadListAdd', newBread) // 再替换新的层级
                } else {
                    this.$store.commit('breadListRemove', parseInt(breadNumber)); //如果往回点面包屑导航，截取；
                }
                this.breadList = this.vuexBreadList;
            },
        }
    }
</script>

<style lang="less" scoped>
    .bread-crumb {
        width: 990px;
        margin: 0 auto;

        @media screen and (min-width:1250px) {
            width: 1200px;
        }
    }
</style>