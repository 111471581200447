// 身份
const getDefaultState = () => {
  return {
    token: undefined,
    fileToken: undefined,
    role: '', //用户当前身份
    roleId: undefined,
    userInfo: {}, // 用户信息
    isAdmin: false,
    // attendedProject: [],
    defaultAvatar: `this.src="${require("@/assets/images/home/avatar.png")}"`, //默认活动cover图片
    rectAvatar: `this.src="${require("@/assets/images/home/avatar-rectangle.png")}"`, //长条头像
    loadingConfig: {
      lock: true,
      text: 'Loading',
      background: 'rgba(255, 255, 255, 0.7)'
    },
    currentProject: {},
    userRoleList: [],
  }
}
const state = getDefaultState();
const mutations = {
  getToken(state, res) {
    state.token = res;
  },
  getFileToken(state, res) {
    state.fileToken = res;
  },
  getRole(state, res) {
    state.role = res;
  },
  getRoleId(state, res) {
    state.roleId = res;
  },
  getIsAdmin(state, res) {
    state.isAdmin = res;
  },
  // getAttendentList(state, res) {
  //   state.attendedProject = res;
  //   // state.currentProject = res[0]
  // },
  getCurrentProject(state, res) {
    state.currentProject = res
  },
  getUserInfo(state, res) {
    state.userInfo = res;
  },
  getUserRoleList(state, res) {
    state.userRoleList = res;
    state.roleId = res[0].id;
    state.role = res[0].value;
  },
  resetModuleState(state, res) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  mutations,
};