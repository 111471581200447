import Vue from "vue";
import Vuex from "vuex";
// import login from "./modules/login";
import url from "./modules/url";
import user from "./modules/user";
import discuss from "./modules/discuss";
import guide from "./modules/guide"
import course from "./modules/course"
import resource from "./modules/resource"
import basics from "./modules/basics"

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    resetState({ commit }) {
      commit('resetModuleState');
    }
  },
  modules: {
    // login,
    url,
    user,
    discuss,
    guide,
    course,
    resource,
    basics
  }
});