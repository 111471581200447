<template>
  <div class="oss-uploader">
    <!-- <slot :file-list="fileList"> -->
    <template v-if="isInput=='true'">
      <div class="upload_box2">
        <img class="upload_img" src="@/assets/images/upload/upload.png" />
        <p class="upload_title">点击上传</p>
        <input type="file" class="file_btn" @change="choosefile">
      </div>
    </template>
    <el-upload v-else class="uploader-btn" action="" ref="upload" :show-file-list="false" :file-list="fileList"
      :limit="5" :on-change="handleChange" :auto-upload="false" :accept="accept">
      <div class="upload_box">
        <img class="upload_img" src="@/assets/images/upload/upload.png" />
        <p class="upload_title">点击上传</p>
      </div>
      <!-- <el-button slot="trigger" size="small" type="primary">选取文件</el-button> -->
      <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitForm">上传到服务器</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="resumeUpload">继续</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="stopUplosd">暂停</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="abortMultipartUpload">清除切片</el-button> -->
    </el-upload>

    <ul>
      <li v-for="(file,index) in fileList" :key="index">
        <oss-uploader-file  :file="file" @closeMain="closeMain" :list="true" :isInput="isInput" :sourceType="sourceType"></oss-uploader-file>
      </li>
    </ul>
    <!-- </slot> -->
  </div>
</template>

<script>
  import OssUploaderFile from "./oss-file.vue";

  const COMPONENT_NAME = "oss-uploader";

  export default {
    name: COMPONENT_NAME,
    props: {
      isInput: String,
      accept:String,
      sourceType:Number
    },
    computed: {
      // fileList() {
      //   return this.$parent.fileList;
      // }

    },
    data() {
      return {
        fileList: []
      };
    },
    methods: {
      choosefile(e) {
        console.log(e);
        // let obj = {
        //   name : e.target.files[0].name,
        //   size:e.target.files[0].size,
        //   raw:e.target.files[0]
        // }
        this.fileList = e.target.files;
        // console.log('fileList', this.fileList)
      },
      closeMain(data) {
        this.$emit('closeMain', data);
      },
      handleChange(file, fileList) {
        console.log('file--',file)
        console.log('fileList--', fileList)
        this.fileList = fileList;
        // this.file = file.raw
        //   this.computeMD5(file.raw,file)
        // console.log(this.$refs.child)
        // this.$refs.child.computeMD5(file.raw,file);
        this.$emit('Lxupload',file)
      },
      fatherMethod(file) {
        if (this.isInput == 'true') {
          this.fileList = []
        }else{
          this.fileList = this.fileList.filter(row => row.uid !== file.uid)
        }
      }

    },
    components: {
      OssUploaderFile
    }
  };
</script>

<style>
  .oss-uploader {
    position: relative;
  }

  .uploader-btn {
    display: inline-block;
    position: relative;
    padding: 10px 15px;
    font-size: 100%;
    line-height: 1.4;
    color: #666;
    /* border: 1px dashed #666; */
    cursor: pointer;
    border-radius: 2px;
    background: none;
    outline: none;
  }

  .upload_box {
    padding: 10px 20px;
    text-align: center;
    border-radius: 5px;
    border: 1px dashed #409eff;
  }

  .upload_box2 {
    position: relative;
    padding: 10px 20px;
    text-align: center;
    border-radius: 5px;
    border: 1px dashed #409eff;
    width: 60px;
    margin: 0 auto;
  }

  .upload_box2>.file_btn {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    outline: none;
    background-color: transparent;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }

  .upload_img {
    height: 25px;
    vertical-align: middle;
  }

  .upload_title {
    color: #409eff;
    padding-top: 5px;
    font-size: 14px;
    vertical-align: middle;
  }

  .upload_box:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .oss-uploader>ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
</style>