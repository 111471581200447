//综合评价
const bulletinList = () => import("../../views/project/bulletin_list.vue"); //项目简报列表页
const workList = () => import("../../views/project/work_list.vue"); //优秀作业列表页
const qualityCourse = () => import("../../views/project/qualityCourse_list.vue"); //景品课程列表
const pdf = () => import("../../views/project/pdf.vue"); //pdf预览
const coursePersonnel = () => import("../../views/manager/coursePersonnel.vue"); //课程统计-学员详情
const attendanceDetail = () => import("../../views/manager/attendance.vue"); //管理员-考勤统计详情
const excellentworkDetail = () => import("../../views/manager/excellentwork.vue"); //管理员-优秀作业详情
const attendance = () => import("../../views/schedule/attendance.vue"); //项目概况-考勤管理
const health = () => import("../../views/schedule/health.vue"); //项目概况-健康防疫
const ask = () => import("../../views/schedule/ask_question.vue"); //项目概况-我要提问
export default [{
        path: '/bulletin/:id',
        name: 'bulletinList',
        component: bulletinList,
        meta: {
            title: '项目周报列表',
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/workList/:id',
        name: 'workList',
        component: workList,
        meta: {
            title: '优秀作业列表',
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/qualityCourse/:id',
        name: 'qualityCourse',
        component: qualityCourse,
        meta: {
            title: '精品课程列表',
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/pdf/:md5',
        name: 'pdf',
        component: pdf,
        meta: {
            title: 'PDF浏览',
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/coursePersonnel/:id',
        name: 'coursePersonnel',
        component: coursePersonnel,
        meta: {
            title: '学员详情',
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/attendanceDetail',
        name: 'attendanceDetail',
        component: attendanceDetail,
        meta: {
            title: '考勤详情',
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/excellentworkDetail/:id',
        name: 'excellentworkDetail',
        component: excellentworkDetail,
        meta: {
            title: '优秀作业详情',
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/attendance',
        name: 'attendance',
        component: attendance,
        meta: {
            title: '考勤管理',
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/health',
        name: 'health',
        component: health,
        meta: {
            title: '健康防疫',
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/ask',
        name: 'ask',
        component: ask,
        meta: {
            title: '我要提问',
            bread: true,
            keepAlive: false
        },
    },
];