import { get, put, patch, post, fetch , del } from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
const Resources = {
  //获取资源列表
  getResources(data) {
    return get("/fu/center/query_all", data);
  },
  // 删除资源
  ResourcesDel(data) {
    return del("/fu/center/delete/", data);
  },
  // 重命名
  editName(data) {
    return put("/fu/center/update", data);
  },
   //资源圈 -- 获取文件token
  getFileToken(data) {
    return get("/fu/center/queryToken", data);
  },
  //资源圈 -- 预览状态 （文档）
  checkPreview(data) {
    return get("/fu/front/upload/checkPreview", data);
  },
  // 资源圈 -- 文件关系图谱预览（搜索 旧）
  getFileRelation(data) {
    return get("/fu/center/query_relation_condition", data);
  },
  // 资源圈 -- 搜索功能
  searchKey(data) {
    return get("/fu/center/querySearchKey", data);
  },

  // 资源圈 -- 获取演示资源
  getDisplayResources(data) {
    return get("/fu/center/query_group_all", data);
  }
};

// 默认全部倒出
export default Resources;
