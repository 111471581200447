// 项目课管理

//创建项目课
const CourseCreate = () =>
  import("../../views/manage/manage_project/project_create.vue"); //课程创建
const CourseDetail = () =>
  import("../../views/manage/manage_project/project_detail.vue"); //课程详情
const CourseEdit = () =>
  import("../../views/manage/manage_project/project_edit.vue"); //课程编辑
const CourseShare = () =>
  import("../../views/manage/manage_project/project_share.vue"); //课程分享
const TeachingData = () =>
  import("../../views/manage/manage_project/project_teachData.vue"); //教学数据
const DiscussManage = () =>
  import("../../views/manage/manage_project/project_discuss.vue"); //讨论区管理

// 课节管理
const LessonManage = () =>
  import("../../views/manage/manage_project/lesson/lesson_home.vue"); //入口
const LessonDetail = () =>
  import("../../views/manage/manage_project/lesson/lesson_detail.vue"); //课节详情
const lessonReplay = () =>
  import("../../views/manage/manage_project/lesson/lesson_replay.vue"); //直播回放
// 课节管理 创建课节
const LessonCreate = () =>
  import("../../views/manage/manage_project/lesson/lesson_create.vue") //创建课节
const LessonCreateAsync = () =>
  import("../../views/manage/manage_project/lesson/component/create_async.vue") //创建 异步课节
const LessonCreateLarge = () =>
  import("../../views/manage/manage_project/lesson/component/create_large.vue") //创建 同步百家云大班课 课节
const LessonCreateSmall = () =>
  import("../../views/manage/manage_project/lesson/component/create_small.vue") //创建 同步百家云小班课 课节
const LessonCreateClassin = () =>
  import("../../views/manage/manage_project/lesson/component/create_classin.vue") //创建 同步classin 课节
const LessonCreateTencent = () =>
  import("../../views/manage/manage_project/lesson/component/create_tencent.vue") //创建 同步腾讯云课堂 课节
// 课节管理 编辑
const LessonEdit = () =>
  import("../../views/manage/manage_project/lesson/lesson_edit.vue"); //课节编辑
const LessonEditAsync = () =>
  import("../../views/manage/manage_project/lesson/component/edit_async.vue"); //编辑 异步课节
const LessonEditLarge = () =>
  import("../../views/manage/manage_project/lesson/component/edit_large.vue"); //编辑 同步百家云大班课 课节
const LessonEditSmall = () =>
  import("../../views/manage/manage_project/lesson/component/edit_small.vue"); //编辑 同步百家云小班课 课节
const LessonEditClassin = () =>
  import("../../views/manage/manage_project/lesson/component/edit_classin.vue"); //编辑 同步classin  课节
const LessonEditTencent = () =>
  import("../../views/manage/manage_project/lesson/component/edit_tencent.vue"); //编辑 同步腾讯云课堂  课节


//公开课管理
const PublicEntrance = () =>
  import("../../views/manage/manage_public/public_entrance.vue"); //公开课详情父级
const publicCreate = () =>
  import("../../views/manage/manage_public/public_create.vue"); //创建公开课
const publicDetail = () =>
  import("../../views/manage/manage_public/public_detail.vue"); //公开课详情
const publicEdit = () =>
  import("../../views/manage/manage_public/public_edit.vue"); //公开课详情编辑


// 活动管理
const ActivityEntrance = () =>
  import("../../views/manage/manage_activity/activity_entrance.vue"); //活动管理父级
const ActivityDetail = () =>
  import("../../views/manage/manage_activity/activity_detail.vue"); //活动详情
const ActivityEdit = () =>
  import("../../views/manage/manage_activity/activity_edit.vue"); //活动编辑
const ActivityCreate = () =>
  import("../../views/manage/manage_activity/activity_create.vue"); //创建活动
// const ActivityMatchStageCreate = () =>
//   import("../../views/manage/manage_activity/activity_match_stage_create.vue"); //创建环节
// const ActivityMatchStageEdit = () =>
//   import("../../views/manage/manage_activity/activity_match_stage_edit.vue"); //编辑环节
// const ActivitySignUp = () =>
//   import("../../views/manage/manage_activity/activity_signup.vue"); //活动报名情况
const ActivityStageDetail = () =>
  import("../../views/manage/manage_activity/activity_child_match.vue"); //子活动（大赛类）
const ActivityChildDetail = () =>
  import("../../views/manage/manage_activity/activity_child_wall.vue"); //子活动（教研类/合作学习墙）
const ActivityRule = () =>
  import("../../views/manage/manage_activity/activity_rule.vue"); //活动规则
const ActivityData = () =>
  import("../../views/manage/manage_activity/activity_data_wall.vue"); //活动数据(合作学习墙)
const ActivityChildData = () =>
  import("../../views/manage/manage_activity/components/wall-data-item.vue"); //子活动详细数据

// 作业 学员整体数据
const workData = () =>
  import("../../views/manage/manage_work/job_data.vue"); //活动报名情况


// 研讨沙龙
const SalonEntrance = () =>
  import("../../views/manage/manage_salon/salon_entrance.vue"); //沙龙入口
const SalonDetail = () =>
  import("../../views/manage/manage_salon/salon_detail.vue"); //查看沙龙
const SalonCreate = () =>
  import("../../views/manage/manage_salon/salon_create.vue"); //创建沙龙
const SalonEdit = () =>
  import("../../views/manage/manage_salon/salon_edit.vue"); //编辑沙龙
const SalonLessons = () =>
  import("../../views/manage/manage_salon/lesson/lesson_home.vue"); //沙龙课节管理
const SalonLessonDetail = () =>
  import("../../views/manage/manage_salon/lesson/lesson_detail.vue"); //沙龙课节详情
const SalonLessonCreate = () =>
  import("../../views/manage/manage_salon/lesson/lesson_create.vue"); //沙龙课节创建
const SalonLessonEdit = () =>
  import("../../views/manage/manage_salon/lesson/lesson_edit.vue"); //沙龙课节编辑

const Wjx = () => import("../../views/manage/manage_wjx/wjx_home.vue") // 问卷星主页
const WjxCreate = () => import("../../views/manage/manage_wjx/wjx_create.vue") // 问卷星创建
const WjxIframe = () => import("../../views/manage/manage_wjx/wjx_iframe.vue") // 问卷星 iframe

const SpaceEntrance =()=>import("../../views/manage/manage_space/space_entrance.vue") // K吧创建
const SpaceCreate =()=>import("../../views/manage/manage_space/create_space.vue") // K吧创建



export default [
  // 课程
  {
    path: "/course/create/:id",
    name: "projectclass",
    component: CourseCreate,
    meta: {
      title: "创建课程",
      bread: true,
      keepAlive: false
    }
  },
  {
    path: "/course/detail/:id",
    name: "courseDetail",
    component: CourseDetail,
    meta: {
      title: "查看课程",
      bread: true,
      keepAlive: false
    }
  },
  {
    path: "/course/edit/:id",
    name: "courseEdit",
    component: CourseEdit,
    meta: {
      title: "编辑课程",
      bread: true,
      keepAlive: false
    }
  },
  {
    path: "/course/share/:id",
    name: "courseShare",
    component: CourseShare,
    meta: {
      title: "分享课程",
      bread: true,
      keepAlive: false
    }
  },
  {
    path: "/teachingdata/:id",
    name: "teachingdata",
    component: TeachingData,
    meta: {
      title: "教学数据",
      bread: true,
      keepAlive: false
    }
  },
  // 课节
  {
    path: "/lesson/:id",
    name: "lesson",
    component: LessonManage,
    meta: {
      title: "课节管理",
      bread: true,
      keepAlive: false
    }
  },
  // {
  //   path: "/projectclasslessons/:id",
  //   name: "projectclasslessons",
  //   component: ProjectClassLessons,
  //   meta: {
  //     title: "创建课节",
  //     keepAlive: false
  //   }
  // },

  {
    path: "/lesson/create",
    name: "lessonCreate",
    component: LessonCreate,
    meta: {
      title: "创建课节",
      keepAlive: false
    },
    children: [{
      path: "large/:id",
      name: "LessonCreateLarge",
      component: LessonCreateLarge,
      meta: {
        // title: "创建同步课课节",
        title: "创建课节",
        bread: true,
        keepAlive: true
      }
    }, {
      path: "small/:id",
      name: "LessonCreateSmall",
      component: LessonCreateSmall,
      meta: {
        // title: "创建小班课课节",
        title: "创建课节",
        bread: true,
        keepAlive: true
      }
    }, {
      path: "classin/:id",
      name: "LessonCreateClassin",
      component: LessonCreateClassin,
      meta: {
        // title: "创建classin标准课课节",
        title: "创建课节",
        bread: true,
        keepAlive: false
      }
    },
    {
      path: "tencent/:id",
      name: "LessonCreateTencent",
      component: LessonCreateTencent,
      meta: {
        // title: "创建腾讯云课堂课节",
        title: "创建课节",
        bread: true,
        keepAlive: false
      }
    }, {
      path: "async/:id",
      name: "LessonCreateAsync",
      component: LessonCreateAsync,
      meta: {
        // title: "创建异步课课节",
        title: "创建课节",
        bread: true,
        keepAlive: true
      }
    },
    ]
  },
  {
    path: "/lesson/detail/:id",
    name: "lessonDetail",
    component: LessonDetail,
    meta: {
      title: "查看课节",
      bread: true,
      keepAlive: true
    }
  },
  {
    // path: "/lesson/edit/:id",
    path: "/lesson/edit",
    name: "lessonEdit",
    component: LessonEdit,
    meta: {
      title: "编辑课节",
      keepAlive: true
    },
    children: [{
      path: "large/:id",
      name: "LessonEditLarge",
      component: LessonEditLarge,
      meta: {
        // title: "编辑同步课课节",
        title: "编辑课节",
        bread: true,
        keepAlive: true
      }
    }, {
      path: "small/:id",
      name: "LessonEditSmall",
      component: LessonEditSmall,
      meta: {
        // title: "编辑小班课课节",
        title: "编辑课节",
        bread: true,
        keepAlive: true
      }
    }, {
      path: "classin/:id",
      name: "LessonEditClassin",
      component: LessonEditClassin,
      meta: {
        // title: "编辑classin标准课课节",
        title: "编辑课节",
        bread: true,
        keepAlive: false
      }
    },
    {
      path: "tencent/:id",
      name: "LessonEditTencent",
      component: LessonEditTencent,
      meta: {
        // title: "编辑腾讯云课堂课节",
        title: "编辑课节",
        bread: true,
        keepAlive: false
      }
    }, {
      path: "async/:id",
      name: "LessonEditAsync",
      component: LessonEditAsync,
      meta: {
        // title: "编辑异步课课节",
        title: "编辑课节",
        bread: true,
        keepAlive: true
      }
    },
    ]
  },
  {
    path: "/lessonReplay/:id",
    name: "lessonReplay",
    component: lessonReplay,
    meta: {
      title: "直播回放",
      bread: true,
      keepAlive: true
    }
  },
  {
    path: "/discussmanage",
    name: "discussmanage",
    component: DiscussManage,
    meta: {
      title: "讨论区管理",
      keepAlive: true
    }
  },

  {
    //公开课课节入口
    path: "/public",
    name: "publiclesson",
    component: PublicEntrance,
    meta: {
      title: "公开课课节",
      keepAlive: true
    },
    // redirect: { name: "publicDetail" },
    children: [
      // 创建公开课
      {
        path: "create",
        name: "publicCreate",
        component: publicCreate,
        meta: {
          title: "创建课程",
          bread: true,
          keepAlive: false
        }
      },
      {
        //公开课课节详情
        path: "detail/:id",
        name: "publicDetail",
        component: publicDetail,
        meta: {
          title: "查看课程",
          bread: true,
          keepAlive: false
        }
      },
      {
        //公开课课节编辑
        path: "edit/:id",
        name: "publicEdit",
        component: publicEdit,
        meta: {
          title: "编辑课程",
          bread: true,
          keepAlive: true
        }
      }
    ]
  },

  {
    //活动管理入口
    path: "/activity",
    name: "activityEntrance",
    component: ActivityEntrance,
    meta: {
      title: "活动管理",
      keepAlive: true
    },
    redirect: {
      name: "activityDetail"
    },
    children: [{
      //活动管理详情
      path: "detail/:id",
      name: "activityDetail",
      component: ActivityDetail,
      meta: {
        title: "活动详情",
        bread: true,
        keepAlive: false
      }
    },
    {
      //活动管理编辑
      path: "edit/:id",
      name: "activityEdit",
      component: ActivityEdit,
      meta: {
        title: "编辑活动",
        bread: true,
        keepAlive: false
      }
    },
    {
      path: "add",
      name: "activityAdd",
      component: ActivityCreate,
      meta: {
        title: "创建活动",
        bread: true,
        keepAlive: false
      }
    },
    // {
    //   path: "create/stage/:id",
    //   name: "activityStage",
    //   component: ActivityMatchStageCreate,
    //   meta: {
    //     title: "创建大赛子环节",
    //     keepAlive: false
    //   }
    // },
    // {
    //   path: "edit/stage/:id",
    //   name: "activityStageEdit",
    //   component: ActivityMatchStageEdit,
    //   meta: {
    //     title: "编辑大赛子环节",
    //     keepAlive: false
    //   }
    // },
    // {
    //   path: "signUp/:id",
    //   name: "ActivitySignUp",
    //   component: ActivitySignUp,
    //   meta: {
    //     title: "报名情况",
    //     keepAlive: true
    //   }
    // },
    {
      path: "stage/detail/:id",
      name: "ActivityStageDetail",
      component: ActivityStageDetail,
      meta: {
        title: "子活动",
        bread: true,
        keepAlive: false
      }
    },
    {
      path: "child/detail/:id",
      name: "ActivityChildDetail",
      component: ActivityChildDetail,
      meta: {
        title: "子活动",
        bread: true,
        keepAlive: true
      }
    },
    {
      path: "child/data/:id",
      name: "ActivityChildData",
      component: ActivityChildData,
      meta: {
        title: "子活动数据",
        bread: true,
        keepAlive: false
      }
    },
    {
      path: "rule/:id",
      name: "ActivityRule",
      component: ActivityRule,
      meta: {
        title: "活动规则",
        bread: true,
        keepAlive: false
      }
    },
    {
      path: "data/:id",
      name: "ActivityData",
      component: ActivityData,
      meta: {
        title: "活动数据",
        bread: true,
        keepAlive: false
      }
    },
    ]
  },

  {
    //学员整体数据
    path: "/job/data",
    name: "workData",
    component: workData,
    meta: {
      title: "学员整体数据",
      keepAlive: false
    },
  },

  {
    //研讨沙龙入口
    path: "/salon",
    name: "salonEntrance",
    component: SalonEntrance,

    meta: {
      title: "研讨沙龙入口",
      keepAlive: true
    },
    redirect: {
      name: "salonCreate"
    },
    children: [
      {
        //研讨沙龙创建
        path: "detail/:id",
        name: "salonDetail",
        component: SalonDetail,
        meta: {
          title: "查看沙龙",
          bread: true,
          keepAlive: false
        }
      },
      {
        //研讨沙龙创建
        path: "create/:currProId",
        name: "salonCreate",
        component: SalonCreate,
        meta: {
          title: "创建沙龙",
          bread: true,
          keepAlive: false
        }
      }, {
        //研讨沙龙编辑
        path: "edit/:id",
        name: "salonEdit",
        component: SalonEdit,
        meta: {
          title: "编辑沙龙",
          bread: true,
          keepAlive: false
        }
      }, {
        //研讨沙龙课节管理
        path: "lessons/:id",
        name: "salonLessons",
        component: SalonLessons,
        meta: {
          title: "沙龙课节管理",
          bread: true,
          keepAlive: false
        }
      },
      {
        //研讨沙龙课节详情
        path: "lessons/detail/:id",
        name: "salonLessonDetail",
        component: SalonLessonDetail,
        meta: {
          title: "沙龙课节详情",
          bread: true,
          keepAlive: false
        }
      },
      {
        //研讨沙龙课节创建
        path: "lessons/create/:id",
        name: "salonLessonCreate",
        component: SalonLessonCreate,
        meta: {
          title: "沙龙课节创建",
          bread: true,
          keepAlive: false
        }
      },
      {
        //研讨沙龙课节编辑
        path: "lessons/edit/:id",
        name: "salonLessonEdit",
        component: SalonLessonEdit,
        meta: {
          title: "沙龙课节编辑",
          bread: true,
          keepAlive: false
        }
      },
      {
        //研讨沙龙创建
        path: "create2/:currProId",
        name: "salonCreate",
        component: WjxCreate,
        meta: {
          title: "创建问卷",
          bread: true,
          keepAlive: false
        }
      },
      {
        //问卷星创建
        path: "wjxIframe",
        name: "wjxIframe",
        component: WjxIframe,
        meta: {
          title: "问卷星详情",
          bread: true,
          keepAlive: false
        }
      }
    ]
  },
  {
    //问卷星入口
    path: "/wjx",
    name: "wjx",
    component: Wjx,
    meta: {
      title: "问卷星入口",
      keepAlive: true
    },
    redirect: {
      name: "create"
    },
    children: [{
      //问卷星创建
      path: "create",
      name: "create",
      component: WjxCreate,
      meta: {
        title: "新建",
        bread: true,
        keepAlive: false
      }
    }, {
      //问卷星创建
      path: "/wjxIframe",
      name: "wjxIframe",
      component: WjxIframe,
      meta: {
        title: "问卷详情",
        bread: true,
        keepAlive: false
      }
    }]
  },
  // 研训空间(K吧)入口
  { 
    path:'/space',
    name:'spaceEntrance',
    component:SpaceEntrance,
    meta: {
      title: "研训空间入口",
      keepAlive: true
    },
    redirect: {
      name: "spaceEntrance"
    },
    children:[
      {
        path:'spaceCreate',
        name:'SpaceCreate',
        component:SpaceCreate,
        meta: {
          title: "创建空间",
          bread: true,
          keepAlive: false
        }
      }
    ]
  }
];