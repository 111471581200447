//个人中心
const My = () =>
    import("../views/my/my_home.vue"); //个人中心父级
const MyLog = () =>
    import("../views/my/my_log.vue"); //我的日志
const MyInfoSetting = () =>
    import("../views/my/my_info_setting.vue"); //基本信息设置
const MyHonour = () =>
    import("../views/my/my_honour.vue"); //我的荣誉
const MyModifyPsd = () =>
    import("../views/my/my_modify_psd.vue"); //修改密码
const MySuggest = () =>
    import("../views/my/my_suggest.vue"); //意见建议
const MyClass = () =>
    import("../views/my/my_class.vue"); // 我的班级
const MyBindLicence = () =>
    import("../views/my/my_bind_licence.vue"); // 我的班级
const MyStudyLog = () => import("../views/my/my_study_log.vue"); // 学习成长记录
export default [{
    path: "/my",
    name: "my",
    component: My,
    redirect: {
        name: "myInfoSetting"
    },
    meta: {
        title: "个人中心",
        keepAlive: false
    },
    children: [
        // {
        //       path: 'log',
        //       name: 'mylog',
        //       component: MyLog,
        //       meta: {
        //         id: 0,
        //         title: "我的日志",
        //         keepAlive: true
        //       }
        //     },
        {
            path: "infoSetting",
            name: "myInfoSetting",
            component: MyInfoSetting,
            meta: {
                id: 0,
                title: "基本信息设置",
                keepAlive: false
            }
        },
        {
            path: "honour",
            name: "myHonour",
            component: MyHonour,
            meta: {
                id: 1,
                title: "我的荣誉/获奖",
                keepAlive: false
            }
        },
        {
            path: "modifyPsd",
            name: "myModifyPsd",
            component: MyModifyPsd,
            meta: {
                id: 2,
                title: "修改密码",
                keepAlive: false
            }
        },
        {
            path: "bindLicence",
            name: "myBindLicence",
            component: MyBindLicence,
            meta: {
                id: 3,
                title: "绑定授权",
                keepAlive: false
            }
        },
        // {
        //   path: "suggest",
        //   name: "mySuggest",
        //   component: MySuggest,
        //   meta: {
        //     id: 4,
        //     title: "意见建议",
        //     keepAlive: true
        //   }
        // },
        // {
        //   path: "class",
        //   name: "myClass",
        //   component: MyClass,
        //   meta: {
        //       id:5,
        //       title: '我的班级',
        //       keepAlive: true
        //   },
        // },
        {
            path: "studylog",
            name: "myStudyloge",
            component: MyStudyLog,
            meta: {
                id: 6,
                title: "学习成长记录",
                keepAlive: false
            }
        }
    ]
}]