import {
    get,
    put,
    patch,
    post,
    Delete
} from '../ApiDecorator' // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
const Form = {
    // ## 问卷相关接口

    // 获取能力标准
    getAllPower() {
        return get('/course/sys/target/listTeachAndStudent')
    },

    //添加问卷   data -- 提交的问卷数据,包括 创建者id、问卷表单json数据、作业主题id、作业属性json数据
    addForm(data) {
        return post("quest/front", data)
    },
    //删除问卷 id -- 问卷id 
    deleteForm(id) {
        return Delete(`quest/front/${id}`)
    },

    //修改问卷（编辑模式） data -- 同提交问卷
    addAgainForm(data) {
        return put("quest/front", data)
    },

    //获取试卷库
    getRepository() {
        return get("quest/mandarin/getMdQuestionnireInfo")
    },

    //查询指定问卷(学员答题,无答案)   id -- 问卷id
    getStuForm(id) {
        return get(`quest/front/q/${id}`)
    },

    //查询指定问卷(专家出题) id -- 问卷id 
    getTeaForm(id) {
        return get(`quest/front/${id}`)
    },

    //查询指定问卷的能力标准 workId -- 问卷id
    getPower(workId) {
        return get(`quest/front/getPowerByQuestionId/${workId}`)
    },

    // 查询指定问卷的能力标准(带权重的)
    getWeightPower(workId) {
        return get(`quest/front/getPowerByQuestionIdV2/${workId}`)
    },

    //查询二级能力标准 workId -- 问卷id
    getSecondPower(workId) {
        return get(`quest/work/getSecondAbility/${workId}`)
    },

    //学员答题提交答案   data -- 问卷id、用户id、表单id、表单id对应的答案(defaultValue)
    submitForm(data) {
        return post("quest/front/answer", data)
    },

    //学员二次提交答案   data -- 上次答案的id、问卷id、用户id、表单id、表单id对应的答案(defaultValue)
    subAgainForm(data) {
        return put("quest/front/answer", data)
    },

    //查询指定问卷的答题情况(包含批改情况)
    checkForm(user_id, quest_id) {
        return get(`quest/front/answer/${user_id}/${quest_id}`)
    },

    // 查询当前主题下的所有问卷列表
    getFormList(themeId) {
        return get(`quest/front/list/theme/${themeId}`)
    },

    //查询用户回答过的问卷列表  user_id -- 用户id
    getUserAnsList(user_id) {
        return get(`quest/front/answer/${user_id}`)
    },

    //查询专家/助教批改过的问卷列表
    getCheckList(user_id) {
        return get(`quest/front/correcting/${user_id}`)
    },

    //题目批改    correctingUserId -- 批改人ID  id -- 问卷答题情况返回的id
    subCheck(data) {
        return put('quest/front/correcting', data)
    },
    //题目批改    correctingUserId -- 批改人ID  id -- 问卷答题情况返回的id
    editFine(data) {
        return put('quest/front/correcting/editFine', data)
    },

    //专家点评  
    subComment(data) {
        return put('/quest/front/correcting/comment', data)
    },

    //查询当前作业下的学员数量
    getStuNum(id) {
        return get(`/quest/front/answer/questionAnswerTotalInfo/${id}`)
    },
    //查询当前作业下的学员列表  data -- 包括作业id、页码、每页数量
    getStuList(data) {
        return get('/quest/front/answer/listByQuestionId', data)
    },

    //获取普通话答题记录
    getreport(data) {
        return get("/quest/mandarin/getHistoryInfo", data)
    },

    //作业管理顶部数据接口
    // getWorkManage(projectId){
    //   return get(`/quest/work/getCountInfo?projectId=${projectId}`)
    // },
    getWorkManage(data) {
        return get('/score/statistics/info', data)
    },

    //查询已提交人员的详情信息(点评模块)
    getSubInfo(data) {
        return get("/quest/front/answer/answerByQuestId", data)
    },


    // ## 作业主题相关接口

    //作业主题列表  (管理员作业管理出现前使用 -- 现暂停使用)
    // getThemeList(data) {
    //     return get('quest/work/selectHomeWorkThemeList', data)
    // },

    //作业主题列表
    getThemeList(data) {
        return get('/quest/work/selectThemeListByExport', data)
    },

    //作业主题信息
    getThemeInfo(themeId) {
        return get(`/quest/work/${themeId}`)
    },

    //添加作业主题
    addTheme(data) {
        return post('/quest/work', data)
    },

    //删除作业主题 id -- 作业主题id
    deleteTheme(id) {
        return Delete(`/quest/work/${id}`)
    },

    //修改作业主题
    updateTheme(data) {
        return put('/quest/work', data)
    },

    //绑定学科下拉选项
    getSubject() {
        return get('/course/query/subject/list')
    },

    //获取绑定课程下拉选项
    getCourse(projectId) {
        return get(`/course/teach/course/listAll/${projectId}`)
    },

    //绑定活动下拉选项
    getActive(projectId) {
        return get(`/course/activity/list/${projectId}`)
    },

    //获取绑定项目期次列表
    getPeriod(projectId) {
        return get(`/course/item/listBySecondId/${projectId}`)
    },

    //获取项目下的用户组
    getUserGroup(projectId) {
        return get(`/course/item/groupInfo/${projectId}`)
    },

    //获取课程下的所有作业
    getWorkList(data) {
        return get('/quest/front/listByBindingId', data)
    },

    //通过学员id获作业主题列表（教师--我的任务列表）
    getTaskList(data) {
        return get('/quest/work/selectByStuId', data)
    },

    //通过学员id获作业主题信息（教师--我的任务列表）
    getTaskInfo(id) {
        return get(`/quest/work/getWorkThemeDetailV2/${id}`)
    },

    //判断当前用户是否已经做过该问卷
    isAnswer(userId, questId) {
        return get(`/quest/answer/getQusetAnswerInfo?userId=${userId}&questId=${questId}`)
    },
    getWorkData(data) {
        return get('/quest/front/answer/data', data)
    },

    //导出作业数据
    downloadwork(data) {
        return get('/quest/front/answer/export', data)
    },

    //批量导出作业
    downloadtask(data) {
        return get('/quest/front/answer/exportZip', data)
    },

    //复制作业
    copywork(data) {
        return post("/quest/front/copy", data)
    },

    //下载打包作业
    downloadpack(data) {
        return get("/quest/front/answer/queryZipList", data)
    },

    //批量打包作业
    makepack(data) {
        return get("quest/front/answer/zipHomeWork", data)
    },
    //批量打包作业
    getFineScore(user_id, quest_id) {
        return get(`quest/front/answer/getFineScore/${user_id}/${quest_id}`)
    },
    //批量打包作业
    getFineComent(user_id, quest_id) {
        return get(`quest/front/correcting/getFineComent/${user_id}/${quest_id}`)
    },
    // 判断是否可以进入后测
    studyFinishV2(data){
        return get('course/word/test/studyFinishV2',data)
    }
}

// 默认全部倒出
export default Form;