import { get, put, patch, post } from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
const My = {
  //查询用户信息
  userInfo(data) {
    return get("/auth/user/info", data);
  },
  //修改密码
  getChange_pwd(data) {
    return put("/auth/user/change_pwd", data);
  },
  //修改个人信息
  getfixinfo(data) {
    return put("/auth/user/fix_info", data);
  },
  getTitle(data) {
    return get("/auth/user/query_title",data)
  },
  getSubject(data) {
    return get("/auth/user/query_subject",data)
  },
  // 获取荣誉列表
  getAwardList(data) {
    return get("/auth/project/getUserAward",data)
  }
};

// 默认全部倒出
export default My;
