//我的课程
const CourseDetail = () => import("../../views/course/course_detail.vue") //我的课程 课程详情
const Live = () => import("../../views/course/live.vue"); //课程直播
const AsyncLive = () => import("../../views/course/live_async.vue"); //异步课程直播
const NewAsyncLive = () => import("../../views/course/live_async_copy.vue"); //新异步课直播
const VideoDetail = () => import("../../views/course/video_detail.vue"); //智慧学堂、作业查看视频详情
// 我的作业
const TaskDetail = () => import("../../views/course/myTask_detail.vue"); //我的作业 任务详情

// 我的活动
const MyActivityDetail = () => import("../../views/course/myActivity_detail.vue"); //我的活动 活动详情
// 新活动


const ActivityReportTeacher = () =>
  import("../../views/course/myActiveReport_teacher.vue"); //我的活动 大赛报告老师
const ActivitySelection = () =>
  import("../../views/course/myActivity_selection.vue"); //我的活动 作品评选
//   const now = new Date()
//   return {
//     name: (now.getFullYear() + parseInt(route.params.id)) + '!'
//   }
// }

export default [{
    path: "/course/:id",
    name: "courseInfo",
    component: CourseDetail,
    // redirect: { name: "desc" },
    props: route => ({
      query: route.query
    }),
    // beforeEnter:(to,from,next)=>{
    //   console.log('to',to)
    //   console.log('from',from)
    //   next();
    // },
    meta: {
      title: "课程详情",
      name: "课程详情",
      bread: true,
      keepAlive: false,
    },
  },
  {
    path: "/live",
    name: "live",
    component: Live,
    meta: {
      title: "观看直播",
      keepAlive: true
    }
  },
  {
    path: "/asyncLive",
    name: "asyncLive",
    component: AsyncLive,
    meta: {
      title: "异步课直播",
      bread: true,
      keepAlive: false
    }
  },
  {
    path: "/newasyncLive",
    name: "newAsyncLive",
    component: NewAsyncLive,
    meta: {
      title: "异步课直播",
      bread: true,
      keepAlive: false
    }
  },
  {
    path: "/videodetail",
    name: "videodetail",
    component: VideoDetail,
    meta: {
      title: "视频详情",
      keepAlive: false
    }
  },
  {
    path: "/mytask/detail/:id",
    name: "taskDetail",
    component: TaskDetail,
    meta: {
      title: "作业主题详情",
      bread: true,
      keepAlive: false
    }
  },
  {
    path: "/myActivity/detail/:id",
    name: "myActivityDetail",
    component: MyActivityDetail,
    meta: {
      title: "活动详情",
      bread: true,
      keepAlive: false
    }
  },
  {
    path: "/myActivity/report/:id",
    name: "activityReportTeacher",
    component: ActivityReportTeacher,
    meta: {
      title: "大赛报告",
      bread: true,
      keepAlive: false
    }
  },
  {
    path: "/myActivity/selection/:id",
    name: "ActivitySelection",
    component: ActivitySelection,
    meta: {
      title: "大赛报告",
      bread: true,
      keepAlive: false
    }
  },
];