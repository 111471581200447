<template>
  <!-- 分页 -->
  <div class="pagination">
    <el-pagination :background="background" :current-page.sync="currentPage" :page-size.sync="pageSize" :layout="layout"
      :page-sizes="pageSizes" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    props: {
      total: {
        required: true,
        type: Number
      },
      page: {
        type: Number,
        default: 1 //默认第一页
      },
      limit: {
        type: Number,
        default: 10 //默认每页20条
      },
      pageSizes: {
        type: Array,
        default () {
          return [10, 20, 30, 50] //默认显示可选的每页多少条数据
        }
      },
      layout: {
        type: String,
        //   default: 'total, sizes, prev, pager, next, jumper'
        default: 'prev, pager, next, sizes'
      },
      background: {
        type: Boolean,
        default: false
      },
      autoScroll: {
        type: Boolean,
        default: true
      },
      hidden: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {}
    },
    computed: {
      currentPage: {
        get() {
          return this.page
        },
        set(val) {
          this.$emit('update:page', val) //改变的第几页的值赋值给父组件
        }
      },
      pageSize: {
        get() {
          return this.limit
        },
        set(val) {
          this.$emit('update:limit', val) //改变的当前页几条数据的值赋值给父组件
        }
      }
    },
    methods: {
      // 改变每页条数
      handleSizeChange(val) {
        this.$emit('pagination', {
          pageIndex: 1,
          pageSize: val
        })
      },
      // 翻页
      handleCurrentChange(val) {
        this.$emit('pagination', {
          pageIndex: val,
          pageSize: this.pageSize
        })
      },
    },
    mounted() {}
  }
</script>

<style lang="less" scoped>
  .pagination {
    margin: 10px 0;
    text-align: center;

    /deep/.el-pagination {
      .btn-prev,
      .btn-next {
        background-color: transparent;
      }

      .el-pager .number {
        color: #777777;
        height: 30px;
        min-width: 30px;
        border: 1px solid #dfdfdf;
        border-radius: 4px;
        padding: 0;
        margin: 0 4px
      }

      .el-pager .active {
        color: #fff;
        background-color: #508EF9;
        border: 1px solid #508EF9;
      }

      .el-pager li.active+li {
        // border-left: 1px solid #dfdfdf;
      }

      .el-pager li.btn-quicknext,
      .el-pager li.btn-quickprev {
        background-color: transparent;
      }
    }
  }
</style>