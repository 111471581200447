<template>
  <div id="app" class="flex-column">
    <headers v-if="!$route.meta.hasNotHeader"></headers>
    <breadcrumb v-show="$route.meta.bread"></breadcrumb>
    <keep-alive>
      <router-view v-if="isRouterAlive && $route.meta.keepAlive" class="router-outview"
        v-wechat-title="`${$route.meta.title}_双优云桥-智慧研修平台`"></router-view>
    </keep-alive>
    <router-view v-if="isRouterAlive && !$route.meta.keepAlive" class="router-outview"
      v-wechat-title="`${$route.meta.title}_双优云桥-智慧研修平台`"></router-view>
    <Footer v-if="!$route.meta.hasNotHeader"></Footer>
    <!-- <router-view></router-view> -->
  </div>
</template>
<script>
  export default {
    name: "app",
    provide() {
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        isRouterAlive: true,
      };
    },
    components: {},
    computed: {},
    watch: {
      $route(to, from) {
        // console.log('to', to);
        // console.log('from', from);
        if (from.meta.keepAlive) {
          from.meta.savedPosition = document.documentElement.scrollTop;
        }
        if (to.name == 'item-list' || to.name == 'schedule') {
          from.meta.savedPosition = 0
        }
      }
    },
    mounted() {
      // 取消开始的loading动画
      const preLoader = document.querySelector('#pre-loader')
      preLoader.style.display = 'none'

      // fix: firefox 下 拖拽 会新打卡一个选项卡
      document.body.ondrop = event => {
        event.preventDefault()
        event.stopPropagation()
      }
    },
    methods: {
      reload() {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
        })
      },
      show() {
        this.show = true
      },
    },
    created() {}
  };
</script>
<style lang="less">
  @import "./styles/frontend.less";

  #app {
    min-height: 100vh;
    min-width: 1000px;

    .router-outview {
      flex: 1;
    }
  }

  

  .el-tooltip__popper {
    max-width: 385px !important;
    line-height: 1.8;
  }
</style>