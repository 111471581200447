// import Vue from 'vue'
// import VueRouter from 'vue-router'
// const Home = () => import('@/views/index/Home.vue');

const Design = () =>
    import('../views/index/design_mode.vue');
const Answer = () =>
    import('../views/index/answer_mode.vue');
const AnswerAgain = () =>
    import('../views/index/answerAgain_mode.vue');
const Check = () =>
    import('../views/index/check_mode.vue');
const View = () =>
    import('../views/index/view_mode.vue');
const Edit = () =>
    import('../views/index/edit_mode.vue');
const FormList = () =>
    import('../views/index/form_list.vue');
const ViewList = () =>
    import('../views/index/view_list.vue');
const ViewMode = () =>
    import('../views/index/view_mode.vue');
const WorkReport = () =>
    import('../views/index/work_report.vue');
const ThemeList = () =>
    import('../views/index/theme_list.vue');
const CreateTheme = () =>
    import('../views/index/create_theme.vue');
const CreateTheme_copy = () =>
    import("../views/index/create_theme_copy.vue");
const WorkManage = () =>
    import("../views/index/work_manage.vue");
const StudentData = () =>
    import('../views/index/student_data.vue');
const Confirm = () =>
    import('../views/index/confirm.vue');
const ViewEmpty = () =>
    import('../views/index/view_empty.vue');
const CreateWork = ()=>
    import('../views/index/create_model.vue')

// Vue.use(VueRouter)


export default [
    // {
    //     path: '/home1',
    //     name: 'home',
    //     component: Home,
    //     meta: {
    //         keepAlive: true
    //     },
    // },
    {
        path:'/createWork',
        name:'createWork',
        component:CreateWork,
        meta:{
            keepAlive:false
        }
    },
    {
        path: '/themeList/:id/:createId',
        name: 'ThemeList',
        component: ThemeList,
        meta: {
            keepAlive: true
        },
    },
    {
        path: '/createTheme',
        name: 'CreateTheme',
        component: CreateTheme,
        meta: {
            keepAlive: true
        },
    },
    {
        path: '/createTheme_copy',
        name: 'CreateTheme_copy',
        component: CreateTheme_copy,
        meta: {
            keepAlive: false,
            bread: true,
            title: '创建作业主题'
        },
    },
    {
        path: '/studentdata',
        name: 'StudentData',
        component: StudentData,
        meta: {
            keepAlive: true
        },
    },
    {
        path: '/workManage',
        name: 'WorkManage',
        component: WorkManage,
        meta: {
            keepAlive: true,
            bread: true,
            title: '查看作业详情'
        },
    },
    {
        path: '/design',
        name: 'design',
        component: Design,
        meta: {
            keepAlive: true,
            bread: true,
            title: '创建作业'
        },
    },
    {
        path: '/answer',
        name: 'answer',
        component: Answer,
        meta: {
            keepAlive: true,
            bread: true,
            title: '作业详情'
        },
    },
    {
        path: '/answerAgain',
        name: 'answerAgain',
        component: AnswerAgain,
        meta: {
            keepAlive: true,
            bread: true,
            title: '编辑作业'
        },
    },
    {
        path: '/check',
        name: 'check',
        component: Check,
        meta: {
            keepAlive: true
        },
    },
    {
        path: '/view',
        name: 'view',
        component: View,
        meta: {
            keepAlive: true,
            bread: true,
            title: '作业详情'
        },
    },
    {
        path: '/view_empty',
        name: 'view_empty',
        component: ViewEmpty,
        meta: {
            keepAlive: true,
            bread: true,
            title: '作业详情'
        },
    },
    {
        path: '/edit',
        name: 'Edit',
        component: Edit,
        meta: {
            keepAlive: false,
            bread: true,
            title: '编辑作业'
        },
    },
    {
        path: '/formList',
        name: 'FormList',
        component: FormList,
        meta: {
            keepAlive: true
        },
    },
    {
        path: '/viewList/:userid',
        name: 'ViewList',
        component: ViewList,
        meta: {
            keepAlive: true
        },
    },
    {
        path: '/viewmode',
        name: 'ViewMode',
        component: ViewMode,
        meta: {
            keepAlive: true
        },
    },
    {
        path: '/workReport',
        name: 'WorkReport',
        component: WorkReport,
        meta: {
            keepAlive: true
        },
    },
    {
        path: '/confirm',
        name: 'Confirm',
        component: Confirm,
        meta: {
            bread: true,
            keepAlive: true,
            title: '作业详情'
        },
    },




    {
        path: '/parser',
        name: 'parser',
        component: () =>
            import( /* webpackChunkName: "parser-example" */ '@/components/parser/example/Index.vue'),
        meta: {
            keepAlive: true
        },
    },
    {
        path: '/tinymce',
        name: 'tinymce',
        component: () =>
            import( /* webpackChunkName: "tinymce-example" */ '@/components/tinymce/example/Index.vue'),
        meta: {
            keepAlive: true
        },
    }
];