import PreviewItem from './imageViewer.vue'
const Preview = {};

Preview.install = function (Vue) {
    const PreviewConstructor = Vue.extend(PreviewItem);
    const instance = new PreviewConstructor();
    instance.$mount(document.createElement("div"));
    document.body.appendChild(instance.$el);

    Vue.prototype.$previewImgs = function (url_list = [], index = 0) {
        instance.showViewer = true;
        instance.previewImages = url_list;
        instance.current = index;
    }
}

export default Preview;