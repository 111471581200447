<!-- 引导页 -->
<template>
      <div class="guidepage" @mousewheel.prevent>
            <div :class="[content]">
                  <div class="title">
                        <span>{{title}}</span>

                        <!-- <span style="cursor:pointer"> -->
                        <i class="el-icon-close close" @click="close"></i>
                        <!-- </span> --> nn
                  </div>
                  <img src="@/assets/guide/image/tea_right.png" v-if="tea=='right'" />
                  <img src="@/assets/guide/image/tea_left.png" v-if="tea=='left'" />

                  <div class="explain">{{explain}}</div>
                  <div class="btn flex-align-between">
                        <span>{{num}}/{{role=='expert'?7:role=='teacher'?6:role=='student'?6:role=='leader'?4:7}}</span>
                        <div class="next" @click="close">{{nexttext}}</div>
                  </div>
            </div>
            <!-- <audio controls :src="src"  autoplay="autoplay"></audio> -->
            <!-- <audio  src="../../../assets/1.aac"  autoplay="autoplay"></audio> -->


      </div>
</template>

<script>
      export default {
            props: {
                  title: String,
                  explain: String,
                  num: String,
                  nexttext: String,
                  content: String,
                  src: String,
                  tea: String,
            },
            data() {
                  return {
                        audio: null,
                        srclist: [
                              "../../../assets/1.aac",
                              "../../../assets/2.aac",
                              "../../../assets/3.aac",
                              "../../../assets/4.aac",
                              "../../../assets/5.aac",
                              "../../../assets/6.aac",
                        ],
                        role: this.$parent.identity


                  }
            },
            //方法
            methods: {
                  close() {
                        // console.log("子组件下一步")
                        this.$emit('toggle')
                  }
            },
            watch: {
                  // num(newval){
                  //       console.log("引导步骤",new)
                  // }
            },

            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  // this.audio = document.createElement("AUDIO")
                  // this.audio.src = "static/qilixiang.mp3"
                  // this.audio.autoplay = 'autoplay'
                  // console.log('用户角色--', this.$parent.identity)
            }
      }
</script>
<style lang="less" scoped>
      /* @import url(); 引入css类 */

      .guidepage {
            .content_bottom {
                  position: relative;
                  width: 620px;
                  height: 272px;
                  border-radius: 10px;
                  background-color: #fff;

                  // margin: 270px auto 20px;
                  .title {
                        position: relative;
                        text-align: center;
                        color: #fff;
                        font-size: 26px;
                        height: 80px;
                        background-color: #2573ED;
                        line-height: 80px;
                        border-radius: 10px 10px 0 0;

                        .close {
                              float: right;
                              line-height: 80px;
                              margin-right: 20px;
                              cursor: pointer;
                              z-index: 99;
                        }
                  }

                  .explain {
                        margin-left: 175px;
                        margin-right: 57px;
                        margin-top: 20px;
                        color: #333333;
                        font-size: 18px;
                        line-height: 30px;
                  }

                  .btn {
                        margin: 43px 45px 0 173px;

                        span {
                              color: #888888;
                              font-size: 18px;
                        }

                        .next {
                              width: 150px;
                              height: 40px;
                              border-radius: 5px;
                              box-shadow: 0px 3px 7px 0px rgba(22, 115, 255, 0.28);
                              font-size: 16px;
                              background-color: #2373EC;
                              color: #fff;
                              text-align: center;
                              line-height: 40px;
                              cursor: pointer;
                        }
                  }

                  img {
                        position: absolute;
                        top: 24px;
                        left: -80px;
                  }

                  &::before {
                        content: "";
                        position: absolute;
                        left: 200px;
                        bottom: -56px;
                        width: 50px;
                        height: 50px;
                        margin: 50px;
                        transform: rotate(45deg);
                        background: #fff;
                        border-bottom: 1px solid #eee;
                        border-right: 1px solid #eee;
                        z-index: -1;
                  }

            }

            .content_top {
                  position: relative;
                  width: 620px;
                  height: 272px;
                  border-radius: 10px;
                  background-color: #fff;

                  // margin: 270px auto 20px;
                  .title {
                        position: relative;
                        text-align: center;
                        color: #fff;
                        font-size: 26px;
                        height: 80px;
                        background-color: #2573ED;
                        line-height: 80px;
                        border-radius: 10px 10px 0 0;

                        .close {
                              float: right;
                              line-height: 80px;
                              margin-right: 20px;
                              cursor: pointer
                        }
                  }

                  .explain {
                        margin-left: 175px;
                        margin-right: 57px;
                        margin-top: 20px;
                        color: #333333;
                        font-size: 18px;
                        line-height: 30px;
                  }

                  .btn {
                        margin: 23px 45px 0 173px;

                        span {
                              color: #888888;
                              font-size: 18px;
                        }

                        .next {
                              width: 150px;
                              height: 40px;
                              border-radius: 5px;
                              box-shadow: 0px 3px 7px 0px rgba(22, 115, 255, 0.28);
                              font-size: 16px;
                              background-color: #2373EC;
                              color: #fff;
                              text-align: center;
                              line-height: 40px;
                              cursor: pointer;
                        }
                  }

                  img {
                        position: absolute;
                        top: 24px;
                        left: -80px;
                  }

                  &::before {
                        content: "";
                        position: absolute;
                        right: 0px;
                        top: -56px;
                        width: 50px;
                        height: 50px;
                        margin: 50px;
                        transform: rotate(45deg);
                        background: #2573ED;
                        border-bottom: 1px solid #2573ED;
                        border-right: 1px solid #2573ED;
                        z-index: -1;
                  }

            }

            .content_top_middle {
                  position: relative;
                  width: 620px;
                  height: 272px;
                  border-radius: 10px;
                  background-color: #fff;

                  // margin: 270px auto 20px;
                  .title {
                        position: relative;
                        text-align: center;
                        color: #fff;
                        font-size: 26px;
                        height: 80px;
                        background-color: #2573ED;
                        line-height: 80px;
                        border-radius: 10px 10px 0 0;

                        .close {
                              float: right;
                              line-height: 80px;
                              margin-right: 20px;
                              cursor: pointer
                        }
                  }

                  .explain {
                        margin-left: 175px;
                        margin-right: 57px;
                        margin-top: 20px;
                        color: #333333;
                        font-size: 18px;
                        line-height: 30px;
                  }

                  .btn {
                        margin: 23px 45px 0 173px;

                        span {
                              color: #888888;
                              font-size: 18px;
                        }

                        .next {
                              width: 150px;
                              height: 40px;
                              border-radius: 5px;
                              box-shadow: 0px 3px 7px 0px rgba(22, 115, 255, 0.28);
                              font-size: 16px;
                              background-color: #2373EC;
                              color: #fff;
                              text-align: center;
                              line-height: 40px;
                              cursor: pointer;
                        }
                  }

                  img {
                        position: absolute;
                        top: 24px;
                        left: -80px;
                  }

                  &::before {
                        content: "";
                        position: absolute;
                        right: 190px;
                        top: -56px;
                        width: 50px;
                        height: 50px;
                        margin: 50px;
                        transform: rotate(45deg);
                        background: #2573ED;
                        border-bottom: 1px solid #2573ED;
                        border-right: 1px solid #2573ED;
                        z-index: -1;
                  }

            }

            .content_left {
                  position: relative;
                  width: 620px;
                  height: 272px;
                  border-radius: 10px;
                  background-color: #fff;

                  // margin: 270px auto 20px;
                  .title {
                        position: relative;
                        text-align: center;
                        color: #fff;
                        font-size: 26px;
                        height: 80px;
                        background-color: #2573ED;
                        line-height: 80px;
                        border-radius: 10px 10px 0 0;



                        .close {
                              float: right;
                              line-height: 80px;
                              margin-right: 80px;
                              cursor: pointer;
                              z-index: 999;
                              position: relative;
                        }
                  }

                  .explain {
                        margin-left: 90px;
                        margin-right: 135px;
                        margin-top: 20px;
                        color: #333333;
                        font-size: 18px;
                        line-height: 30px;
                  }

                  .btn {
                        margin: 23px 145px 0 90px;

                        span {
                              color: #888888;
                              font-size: 18px;
                        }

                        .next {
                              width: 150px;
                              height: 40px;
                              border-radius: 5px;
                              box-shadow: 0px 3px 7px 0px rgba(22, 115, 255, 0.28);
                              font-size: 16px;
                              background-color: #2373EC;
                              color: #fff;
                              text-align: center;
                              line-height: 40px;
                              cursor: pointer;
                        }
                  }

                  img {
                        position: absolute;
                        top: 24px;
                        right: -80px;
                  }

                  &::before {
                        content: "";
                        position: absolute;
                        right: 190px;
                        top: 56px;
                        left: -56px;
                        width: 50px;
                        height: 50px;
                        margin: 50px;
                        transform: rotate(45deg);
                        background: #fff;
                        border-bottom: 1px solid #fff;
                        border-right: 1px solid #fff;
                        z-index: -1;
                  }

            }

            .content_right {
                  position: relative;
                  width: 620px;
                  height: 272px;
                  border-radius: 10px;
                  background-color: #fff;

                  // margin: 270px auto 20px;
                  .title {
                        position: relative;
                        text-align: center;
                        color: #fff;
                        font-size: 26px;
                        height: 80px;
                        background-color: #2573ED;
                        line-height: 80px;
                        border-radius: 10px 10px 0 0;

                        .close {
                              float: right;
                              line-height: 80px;
                              margin-right: 20px;
                              cursor: pointer
                        }
                  }

                  .explain {
                        margin-left: 175px;
                        margin-right: 57px;
                        margin-top: 20px;
                        color: #333333;
                        font-size: 18px;
                        line-height: 30px;
                  }

                  .btn {
                        margin: 23px 45px 0 173px;

                        span {
                              color: #888888;
                              font-size: 18px;
                        }

                        .next {
                              width: 150px;
                              height: 40px;
                              border-radius: 5px;
                              box-shadow: 0px 3px 7px 0px rgba(22, 115, 255, 0.28);
                              font-size: 16px;
                              background-color: #2373EC;
                              color: #fff;
                              text-align: center;
                              line-height: 40px;
                              cursor: pointer;
                        }
                  }

                  img {
                        position: absolute;
                        top: 24px;
                        left: -80px;
                  }

                  &::before {
                        content: "";
                        position: absolute;
                        right: 0px;
                        top: 56px;
                        right: -56px;

                        width: 50px;
                        height: 50px;
                        margin: 50px;
                        transform: rotate(45deg);
                        background: #fff;
                        border-bottom: 1px solid #fff;
                        border-right: 1px solid #fff;
                        z-index: -1;
                  }

            }
          


      }
</style>