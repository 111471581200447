//引导页
const state = {
      showguide: false,
    };
    
    const mutations = {
      changestatus(state, res) {
        state.showguide = res;
      },
    };
    
    export default {
      state,
      mutations
    };
    