<template>
    <div>
        <uploaderimage :upimgload="upimgload" :options="options" :file-status-text="statusText" class="uploader-example"
            ref="uploader" :autoStart="false" @file-complete="fileComplete" @complete="complete"
            @fileAdded="onFileAdded" @filesAdded="onFilesAdded"></uploaderimage>
    </div>
</template>

<script>
    import axios from "axios";
    import qs from "qs";
    import SparkMD5 from "spark-md5";
    export default {
        name: 'upimg',
        props: ['upimgload'],
        data() {
            return {
                options: {
                    target: axios.defaults.baseURL + "/fu/front/upload/chunk",
                    testChunks: true,
                    headers: {
                        Authorization: "Bearer " + this.$store.state.user.token
                    },
                    // method:'octet',
                    // query(file,chunk,test){
                    //   // console.log('bbb',file,chunk,test)
                    // },
                    //  checkChunkUploadedByResponse: function (chunk, message) { // 根据 XHR 响应内容检测每个块是否上传成功了
                    //     let objMessage = JSON.parse(message);
                    //     // console.log(objMessage)
                    //     if (objMessage.skipUpload) {
                    //         return true;
                    //     }

                    //     return (objMessage.uploaded || []).indexOf(chunk.offset + 1) >= 0
                    // },
                    // preprocess: function (response, cb, File, chunk) { // 每个块在测试以及上传前会被调用，参数就是当前上传块实例 Uploader.Chunk，注意在这个函数中你需要调用当前上传块实例的 preprocessFinished 方法，默认 null
                    //     console.log('chunk', chunk);
                    //     chunk.preprocessFinished()
                    // },
                    simultaneousUploads: 1,
                    chunkSize: 10 * 1024 * 1024
                },
                attrs: {
                    accept: "image/*"
                },
                statusText: {
                    success: "成功了",
                    error: "出错了",
                    uploading: "上传中",
                    paused: "暂停中",
                    waiting: "计算md5...",
                    cmd5: "计算md5..."
                }
            };
        },
        methods: {
            // 上传完成
            onFileAdded(file) {
                this.computeMD5(file);
            },
            onFilesAdded(files, fileList) {
                files.forEach(file => {
                    this.computeMD5(file);
                });
            },
            computeMD5(file) {
                // console.log("计算MD5", file);
                let blobSlice =
                    File.prototype.slice ||
                    File.prototype.mozSlice ||
                    File.prototype.webkitSlice,
                    chunkSize = 10 * 1024 * 1024, //2MB
                    chunks = Math.ceil(file.size / chunkSize),
                    currentChunk = 0,
                    spark = new SparkMD5.ArrayBuffer(),
                    fileReader = new FileReader();

                let time = new Date().getTime();

                file.cmd5 = false; //文件状态为“计算md5...”

                fileReader.onload = e => {
                    spark.append(e.target.result); // Append array buffer
                    currentChunk++;

                    if (currentChunk < chunks) {
                        let md5 = spark.end(); //得到md5
                        console.log(
                            `MD5计算完成：${file.name} \nMD5：${md5} \n分片：${currentChunk} 大小:${
              file.size
            } 用时：${new Date().getTime() - time} ms`
                        );
                        // spark.destroy(); //释放缓存
                        // console.log(
                        //   `第${currentChunk}分片解析完成, 开始第${currentChunk +
                        //     1} / ${chunks}分片解析`
                        // );
                        loadNext();
                    } else {
                        // console.log("finished loading");
                        let md5 = spark.end(); //得到md5
                        function getCaption(obj) {
                            var index = obj.lastIndexOf(".");
                            obj = obj.substring(index + 1, obj.length);
                            return obj;
                        }
                        // console.log(
                        //   `MD5计算完成：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${
                        //     file.size
                        //   } 用时：${new Date().getTime() - time} ms`
                        // );
                        file.filemd5 = md5 + "_" + file.size + "_" + getCaption(file.name);
                        spark.destroy(); //释放缓存
                        // file.uniqueIdentifier = md5; //将文件md5赋值给文件唯一标识
                        file.uniqueIdentifier = file.filemd5; //将文件md5赋值给文件唯一标识
                        //   this.form.cover = file.filemd5;
                        sessionStorage.filemd5 = file.filemd5;
                        file.resume(); //开始上传

                        // axios({
                        //   url:
                        //     axios.defaults.baseURL+"/fu/front/upload/check/file?identifier=" +
                        //     file.filemd5,
                        //   method: "get",
                        //   headers: {
                        //     "Content-Type": "application/x-www-form-urlencoded;charset:UTF-8"
                        //   }
                        // })
                        //   .then(function(res) {
                        //     if (res.data.code == 200) {
                        //       setTimeout(() => {
                        //         file.cmd5 = true;
                        //         console.log(file.cmd5)
                        //          },1000)

                        //       file.pause(); //上传成功
                        //     } else {
                        //       file.cmd5 = false;
                        //       file.resume(); //开始上传
                        //     }
                        //   })
                        //   .catch(function(error) {
                        //     console.log(error);
                        //   });
                    }
                };

                fileReader.onerror = () => {
                    console.warn("oops, something went wrong.");
                    file.cancel();
                };

                let loadNext = () => {
                    let start = currentChunk * chunkSize,
                        end = start + chunkSize >= file.size ? file.size : start + chunkSize;

                    fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end));
                };

                loadNext();
            },
            complete() {
                // console.log("complete", arguments);
            },
            // 一个根文件（文件夹）成功上传完成。
            async fileComplete() {
                // console.log("file complete", arguments);

                const file = arguments[0].file;
                let resData = await this.$Api.Home.fileComplete({
                    filename: file.name,
                    // identifier: arguments[0].uniqueIdentifier,
                    identifier: arguments[0].filemd5,
                    totalSize: file.size,
                    type: file.type,
                    source: this.$route.name === "resourcescenter" ? 1 : 0
                })
                if (resData.code == 200) {
                    this.$emit('closeMain', arguments[0]);
                    // console.log(resData);
                }
            },
            fileCompletetwo() {
                // console.log("file complete", arguments);
                const file = arguments[0].file;

                axios({
                        // url: "/fu/front/upload/mergeFile",
                        url: "/fu/front/upload/merge",
                        method: "post",
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8"
                        },
                        data: qs.stringify({
                            filename: file.name,
                            // identifier: arguments[0].uniqueIdentifier,
                            identifier: arguments[0].filemd5,
                            totalSize: file.size,
                            type: file.type
                        })
                    })
                    .then(res => {
                        if (res.data.code = 200) {
                            this.$emit('closeMain', arguments[0]);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },

        },
        created() {},
    };
</script>

<style>
    .uploader {
        position: relative;
    }
</style>