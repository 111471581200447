//教学管理
import Manage from "./manage-router";
//我的课程
import Course from "./course-router";
//综合评价
import Evaluate from "./evaluate-router";
//能力报告
import Reprot from "./report-router";
//项目主页
import Project from "./project-router";
//其他小模块
import Other from "./other-router";

export default [
    ...Manage,
    ...Course,
    ...Evaluate,
    ...Reprot,
    ...Project,
    ...Other
];
  