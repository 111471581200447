<template>
  <div class="video-play">
    <video v-if="videoType" controls :id="`my-video-detail${file}`" width="400x" height="200" style="display:block"
      class="video-js"></video>
    <video v-else controls :id="`video-detail${file}`" :src="videoSrc" width="200x" height="150"
      style="display:block"></video>
  </div>
</template>

<script>
  import axios from "axios";
  import videojs from 'video.js'
  import 'videojs-contrib-hls'
  export default {
    props: ["id", "file"],
    data() {
      return {
        options: {
          autoplay: true, // 设置自动播放
          muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音（Chrome66及以上版本，禁止音视频的自动播放）
          preload: 'auto', // 预加载
          controls: true // 显示播放的控件
        },
        player: null,
        videoSrc: '', //视频地址
        videoType: false,

      };
    },
    computed: {},
    // watch: {
    //   id(newVal, oldVal) {
    //     console.log(newVal)
    //      if (newVal) {
    //     }
    //   }
    // },
    methods: {
      getVideo(nowPlayVideoUrl) {
        this.player = videojs(`my-video-detail${this.file}`, this.options)
        this.player.src([{
          src: nowPlayVideoUrl, // 地址
          type: 'application/x-mpegURL' // 告诉videojs,这是一个hls流
        }])
      },

      // 新增加函数
      async getDownloadsUrl() {
        let id = this.id;
        console.log()
        let videoSrc = `${this.getDownloadUrl}${id}&type=5`
        axios({
          url: videoSrc,
          method: "get",
        }).then((res) => {
          if (res.data.data.ext === 'm3u8') {
            this.videoType = true;
            this.videoSrc = res.data.data.url;
            setTimeout(() => {
              this.getVideo(this.videoSrc)
            }, 100)
          }
          if (res.data.data.ext !== 'm3u8') {
            this.videoType = false;
            this.videoSrc = res.data.data.url;
            let _video = document.getElementById(`video-detail${file}`);
            setTimeout(() => {
              _video.muted = false;
            }, 1000)
          }
        }).catch(function (error) {
          console.log(error);
        });

      },
    },
    activated() {},
    deactivated() {},
    mounted() {
      this.getDownloadsUrl();
    },
    destroyed() {
      if (!this.player) return;
      this.player.dispose()
    }
  };
</script>

<style lang="less" scoped>
  .video-play {
    width: 100%;

    video {
      width: 100%;
      height: 100%;
      // max-width: 1300px;
      background-color: #000;
    }
  }
</style>