//其他小模块
//---项目动态---
const NewsDetail = () => import("../../views/news/news_detail.vue");
const NewsWork = () => import("../../views/news/news_work.vue"); //
const NewsNotice = () => import("../../views/news/news_notice.vue");
// const NewsAdd = () =>
//     import ("../../views/news/news_add.vue"); //创建项目动态\
//项目概况
const TrainPlan = () =>
    import("../../views/schedule/trainplan.vue") //项目概况 培养计划
// 云端答疑
const QuestionList = () =>
    import("../../views/other/question-list.vue") //云端答疑 列表页
const QuestionDetail = () =>
    import("../../views/other/question.vue") //云端答疑 详情页


export default [{
        path: "/news/detail/:id",
        name: "newsDetail",
        component: NewsDetail,
        meta: {
            title: "动态详情",
            bread: true,
            keepAlive: false
        }
    }, {
        path: "/news/notice",
        name: "newsNotice",
        component: NewsNotice,
        meta: {
            title: "公告详情",
            bread: true,
            keepAlive: false
        }
    },
    {
        path: "/newswork",
        name: "newswork",
        component: NewsWork,
        meta: {
            title: "通知公告",
            bread: true,
            keepAlive: false
        },
    },
    // {
    //     path: "/news/add",
    //     name: "newsAdd",
    //     component: NewsAdd,
    //     meta: {
    //         title: "创建项目动态",
    //         keepAlive: true
    //     }
    // },
    {
        path: "/trainplan/:id",
        name: "trainplan",
        component: TrainPlan,
        meta: {
            title: "培养计划",
            bread: true,
            keepAlive: false
        },
    },
    {
        path: "/question/:id",
        name: "questionList",
        component: QuestionList,
        meta: {
            title: "云端答疑列表",
            bread: true,
            keepAlive: false
        },
    },
    {
        path: "/question/detail/:id",
        name: "questionDetail",
        component: QuestionDetail,
        meta: {
            title: "云端答疑详情",
            bread: true,
            keepAlive: false
        },
    },
];