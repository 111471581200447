//二次封装elementUI
class DoUpElement {
    /* 
        通知 （悬浮出现在页面角落，显示全局的通知提醒消息）
        arg1: 使用时的实例this, arg2: 通知类型 String, arg3: 需要展示的信息 String 
    */
    notify(that,type,msg,duration,callback) {
        let map = {
            'info':"提示",
            'success':"成功",
            'warning':"注意",
            'error':"错误",
        }
        that.$notify({
            title: map[type],
            message: msg,
            type: type,
            duration: duration || 4500,
            onClose:callback
        });
    }
}

export default new DoUpElement();