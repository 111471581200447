//领导
// const Leader = () => import("../views/leader/leader_home.vue"); // 项目概况
// ---教学督导---
const Supervise = () => import("../views/leader/supervise_home.vue"); //教学督导 -- 首页
const SupserviseTodayCourse = () => import("../views/leader/supervise_course.vue"); //教学督导 --今日课程
const SupserviseSchedule = () => import("../views/leader/supervise_schedule.vue"); // 教学督导 -- 课程表
const activeReport = () => import("../views/leader/leader_activeReport.vue"); // 活动报告

const innerMongolia = () => import("../views/leader/performance/innerMongolia/innerMongolia.vue"); //内蒙古中部片区统编三科教师
const kindergarten = () => import("../views/leader/performance/kindergarten/kindergarten.vue"); //2020年新疆和田地区幼儿园园长“云跟岗”培训项目总结


export default [
  // {
  //   path: "/leader",
  //   name: "leader",
  //   component: Leader,
  //   meta: {
  //     title: "领导首页",
  //     keepAlive: true
  //   }
  // },
  {
    path: "/supervise",
    name: "supervise",
    component: Supervise,
    meta: {
      title: "教学督导",
      keepAlive: false
    },
    redirect: {
      name: "todayCourse"
    },
    children: [{
        path: "course",
        name: "todayCourse",
        component: SupserviseTodayCourse,
        meta: {
          title: "今日课程",
          keepAlive: false
        },
      },
      {
        path: "schedule",
        name: "SupserviseSchedule",
        component: SupserviseSchedule,
        meta: {
          title: "课程表",
          keepAlive: false
        },
      },
    ]
  },
  {
    path: "/innerMongolia",
    name: "innerMongolia",
    component: innerMongolia,
    meta: {
      title: "绩效报告",
      keepAlive: false
    },
  },
  {
    path: "/kindergarten",
    name: "kindergarten",
    component: kindergarten,
    meta: {
      title: "绩效报告",
      keepAlive: false
    },
  },
  {
    path: "/leader/report/:id",
    name: "leaderActiveReport",
    component: activeReport,
    meta: {
      title: "活动报告详情",
      bread: true,
      keepAlive: false
    },
  }
];