const SchoolSpace = () =>
    import("../views/dataScreen/schoolSpace.vue"); //学校空间
const county = () =>
    import("../views/dataScreen/county.vue"); //学校空间
const alliance = () =>
    import("../views/dataScreen/alliance.vue"); //盟空间


export default [{
        path: "/schoolSpace",
        name: "schoolSpace",
        component: SchoolSpace,
        meta: {
            title: "学校空间",
            keepAlive: false,
            hasNotHeader: true,
        }
    },
    {
        path: "/county",
        name: "county",
        component: county,
        meta: {
            title: "旗县空间",
            keepAlive: false,
            hasNotHeader: true,
        }
    },
    {
        path: "/alliance",
        name: "alliance",
        component: alliance,
        meta: {
            title: "盟空间",
            keepAlive: false,
            hasNotHeader: true,
        }
    },
]