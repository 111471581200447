<template>
  <div>
    <el-button icon="el-icon-star-on" type="text" @click="godesign"
      >设计模式</el-button
    >
    <el-button icon="el-icon-star-on" type="text" @click="goview"
      >查看模式</el-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    godesign() {
      this.$router.push("/design");
    },
    goview() {
      this.$router.push("/viewList")
    }
  }
};
</script>

<style></style>
