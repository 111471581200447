<template>
  <!-- 下载客户端弹窗 -->
  <div class="download-client">
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="450px" :center="true" @closed="handleClose">
      <div class="toClass" v-if="!downloadVisible">
        <p>客户端直播上课更流畅，功能更强大，建议使用客户端进入教室。</p>
        <p>如未看装客户端，请点击<button @click.prevent="toDownload" class="download-btn csp">下载客户端</button></p>
      </div>
      <div class="DLlient" v-else>
        <button v-for="(item,index) in downloadList" :key="index" @click.prevent="downloadCilent(item)"
          class="flex-align">
          <img :src="item.icon" />
          <span>{{item.name}}</span>
        </button>
      </div>

      <span slot="footer" class="dialog-footer" v-if="!downloadVisible">
        <el-button @click="toClass()" v-if="classType!=5">网页进入教室</el-button>
        <el-button type="primary" @click="toClient($event)" ref="toClient">客户端进入教室</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import protocolCheck from "@/config/utils/protocolcheck.js";
  export default {
    props: {
      isVisible: {
        type: Boolean,
        default: false
      },
      liveLessonId: {
        type: Number
      },
      top: {
        type: String
      },
      classType: {
        type: Number
      },
    },
    watch: {
      isVisible(newVal, oldVal) {
        this.dialogVisible = this.isVisible;
      },
      liveLessonId(newVal, oldVal) {
        // console.log(newVal);
        this.golive();
      },
      top(newVal, oldVal) {
        // console.log(newVal);
      },
      dialogVisible(newVal, oldVal) {
        // console.log(newVal)
        if (!newVal) {
          this.$emit('downClient', {
            visible: false
          })
        }
      }
    },
    data() {
      return {
        dialogTitle: '进入直播教室',
        dialogVisible: false,
        downloadVisible: false,
        downloadList: [{
            name: 'Mac 下载',
            icon: require('@/assets/images/course/mac.png'),
            // byjDownloadUrl: 'https://cdnyun.cnuen.net/package/bjyclientmac7.8.6.dmg',
            byjDownloadUrl: '/download/ydkt.dmg',
            classInDownloadUrl: 'https://cdnyun.cnuen.net/static/file/bridgeofoptimaleducation_mac_install_4.0.3.51.dmg',
            tencentDownLoadUrl: 'https://cdnyun.cnuen.net/package/%E5%8F%8C%E4%BC%98%E4%BA%91%E6%A1%A5%E4%BA%91%E8%AF%BE%E5%A0%82-1.6.3.dmg'
          },
          {
            name: 'Windows 下载',
            icon: require('@/assets/images/course/win.png'),
            // byjDownloadUrl: 'https://cdnyun.cnuen.net/package/bjyclient7.8.6.zip',
            byjDownloadUrl: '/download/ydkt.zip',
            classInDownloadUrl: 'https://cdnyun.cnuen.net/static/file/bridgeofoptimaleducation_win_install_4.0.3.51.exe',
            tencentDownLoadUrl: 'https://cdnyun.cnuen.net/package/%E5%8F%8C%E4%BC%98%E4%BA%91%E6%A1%A5%E4%BA%91%E8%AF%BE%E5%A0%82_1.6.3_Setup.exe'
          },
        ],
        liveurl: "",
      };
    },
    methods: {
      handleClose() {
        this.dialogTitle = '进入直播教室';
        this.downloadVisible = false;
        this.$emit('downClient', {
          visible: false
        })
      },
      toDownload() {
        this.dialogTitle = '下载客户端';
        this.downloadVisible = true;
      },
      downloadCilent(item) {
        // classin客户端下载
        if (this.classType == 5) {
          window.open(item.classInDownloadUrl);
          return;
        }
        // 腾讯云课堂客户端下载
        if (this.classType == 6) {
          window.open(item.tencentDownLoadUrl);
        }
        // 百家云客户端下载
        window.open(item.byjDownloadUrl);
      },
      toClass() {
        let breadLength = this.$store.state.basics.breadList.length
        this.$router.push({
          path: "/live",
          query: {
            id: this.liveLessonId,
            breadNumber: breadLength + 1,
          }
        })
      },
      getCookie(name) {
        if (document.cookie.indexOf(name) != -1) {
          let start = document.cookie.indexOf(name) + name.length + 1;
          let end = document.cookie.indexOf(';', document.cookie.indexOf(name));
          if (end == -1) {
            end = document.cookie.length;
          }
          return document.cookie.substring(start, end);
        }
      },
      async golive() {
        let resData = await this.$Api.Project.live(this.liveLessonId);
        if (JSON.stringify(resData) == '{}') return;
        if (this.classType == 5) {
          // 截取当前页的url
          let hostPath = `${location.href.split('/')[0]}//${location.host}`
          let pageUrl = location.href.substring(hostPath.length, location.href.length);
          // 跳转地址增加token和当前页的url
          let token = this.$store.state.user.token;
          // this.liveurl = `${resData.data.clientUrl}&token=${sessionStorage.getItem('token')}&path=${pageUrl}`
          this.liveurl = `${resData.data.clientUrl}&token=${token}&path=${pageUrl}`
          console.log(this.liveurl)
          return;
        }
        this.liveurl = resData.data.clientUrl;
      },
      toClient(event) {
        protocolCheck(this.liveurl, () => {
          this.$confirm('您的电脑未安装客户端，请问是否安装？(如已安装请忽略)', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.toDownload();
          }).catch(() => {
            // this.$message({type: 'error', message: '服务器异常'})
          })
        })
      },
    },

    mounted() {},
    activated() {
      this.dialogVisible = this.isVisible;
    },
    deactivated() {
      this.dialogVisible = false;
      this.$emit('downClient', {
        visible: false
      })
    }
  }
</script>

<style lang="less" scoped>
  .download-client {

    .toClass,
    .DLlient {
      line-height: 24px;
      font-size: 14px;
      color: #666
    }

    .download-btn {
      color: #508EF9;
    }

    .DLlient {

      button {
        // display: block;
        margin: 0 auto;
        padding: 8px 20px;
        border: 1px solid #c9c9c9;
        border-radius: 2px;
        width: 180px;
        cursor: pointer;
        font-size: 13px;
        color: #666;
        transition: 0.3s box-shadow ease;

        &:first-child {
          margin-bottom: 20px;
        }

        &:hover {
          box-shadow: 0px 3px 10px 0px rgba(60, 108, 195, 0.19);
        }
      }


      img {
        width: 25px;
        margin-right: 15px;
        margin-top: -2px;
      }
    }

    /deep/.el-dialog {
      border-radius: 5px;
      overflow: hidden;
    }

    /deep/.el-dialog__header {
      background-image: url("../../assets/images/teach/create-title.png");
      display: cover;
      padding: 0px;
      line-height: 45px;


      .el-dialog__title {
        color: #fff;
        font-size: 16px;
      }

      .el-dialog__headerbtn {
        top: 10px;
      }

      .el-icon-close:before {
        color: #fff;
      }
    }

    /deep/.el-dialog__footer {
      .el-button {
        padding: 0 25px;
        line-height: 36px;
        border-color: #508EF9;
      }

      .el-button--default {
        color: #508EF9;
        margin-right: 15px;
      }

      .el-button--primary {
        background-color: #508EF9;
      }
    }

    /deep/ .el-dialog__body {
      color: #666;
      padding: 35px 50px;
    }


  }
</style>