import {
  get,
  put,
  patch,
  post,
  fetch,
  Delete
} from "../ApiDecorator"; // 倒入 api
/* 将所有接口统一起来便于维护
 * 如果项目很大可以将 url 独立成文件，接口分成不同的模块
 * 此处的数据依然来自 Easy Mock
 */

// 单独倒出
const DST = {
  // 成果管理-成果沉淀列表
  getlistSediment(data) {
    return get("/course/achievement/listSediment", data);
  },
  // DST 故事列表
  getlistDst(data) {
    return get("/course/achievement/listDst", data);
  },
  // 成果详情
  getDetail(data) {
    return fetch("/course/achievement/getDetail/", data);
  },
  // 教学团队-查询教师信息
  getTeacherInfo() {
    return get("/course/teach/team/teacher_info");
  },
  // 二级项目bar
  getUserProjects() {
    return get("/learn/overview/user_projects");
  },
  // 通过二级项目id 查询分期以及分期下项目
  getlistBySecondId(data) {
    return fetch("/course/item/listBySecondId/", data);
  },
  // 教学团队-查询其他专家信息
  getOtherTeacher(data) {
    // return get("/course/teach/team/otherTeachersPage", data);
    return get("/learn/expert/getList",data)
  },
  // 课程管理-查看课程列表(带分页)
  getList(data) {
    return get("/course/teach/course/getCourseFortTeam", data);
  },
  userSecendAbility(data) {
    return get("/score/scoreAbility/userSecendAbility", data);
  },
  userFristAbility(data) {
    return get("/score/scoreAbility/userFristAbility", data);
  },
  getAbilitySuit(data) {
    return get("score/scoreAbility/getAbilitySuit", data);
  },
  //各维度能力水平
  getUserAbilityAvg(data) {
    return get("/score/scoreAbility/getUserAbilityAvg", data);
  },
  //各维度具体参与情况
  getAbilityDimension(data) {
    return get("/score/scoreAbility/getAbilityDimension", data);
  },
  //各维度具体参与情况
  getUserAbilityScurce(data) {
    return get("/score/scoreAbility/getUserAbilityScurce", data);
  },
  //各维度具体参与情况
  getAbilityPromote(data) {
    return get("/score/scoreAbility/getAbilityPromote", data);
  },
  getMyAbilityAnalysis(data) {
    return get("/score/scoreAbility/getMyAbilityAnalysis", data);
  },
  // 项目专家-创建
  create(data){
    return post("/learn/expert/create",data)
  },
  // 项目专家-编辑 
  edit(data){
    return put("/learn/expert/edit",data)
  },
  // 团队管理列表 
  listByAdmin(data){
    return get("/learn/expert/listByAdmin",data)
  },
  // 项目专家-团队角色 
  getCreateOption(){
    return get("/learn/expert/getCreateOption")
  },
  // 项目专家-删除 
  delete(data){
    return Delete("/learn/expert/delete",data)
  },
  // 项目专家-获取单个专家信息
  one(data){
    return get("/learn/expert/one",data)
  }

};
// 默认全部倒出
export default DST;