import {
    get,
    put,
    post,
    Delete,
    formDataPost
} from "../ApiDecorator"; // 倒入 api

const Space = {
    // 获取K吧选项  
    getCategories() {
        return get("/quest/lx/getCategories");
    },
    // 创建K吧 
    createKBar(data){
        return post("/quest/lx/createKBar",data)
    },
    // 专家K吧列表 
    listForAdmin(data){
        return get("/quest/lx/listForAdmin",data)
    },
    // 教师K吧列表 
    listForTeacher(data){
        return get("/quest/lx/listForTeacher",data)
    },
    // K吧上传 
    upload(data){
        return formDataPost("/quest/lx/upload",data)
    },
    // 删除K吧 /quest/lx/delete
    delete(data){
        return Delete("/quest/lx/delete",data)
    }
}

export default Space