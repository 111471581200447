// 新活动路由
const NewActive = () => import("../views/newactive/activedetail.vue")
const Result = () => import("../views/newactive/result.vue")
const TestResult = () => import("../views/newactive/testresult.vue")
const Match = () => import("../views/newactive/match.vue")
const Report = () => import("../views/newactive/report.vue")
export default [{
        path: '/newActiveDetail/:id',
        name: 'NewActive',
        component: NewActive,
        meta: {
            title: "活动详情",
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/result',
        name: 'Result',
        component: Result,
        meta: {
            title: "成绩查询",
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/testResult',
        name: 'TestResult',
        component: TestResult,
        meta: {
            title: "测评结果",
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/match',
        name: 'Match',
        component: Match,
        meta: {
            title: "正式比赛",
            bread: true,
            keepAlive: false
        },
    },
    {
        path: '/report',
        name: 'Report',
        component: Report,
        meta: {
            title: '大赛报告',
            bread: true,
            keepAlive: false
        }
    }
]