<template>
    <div class="upload-cover" @file-change="handleCoverChange">
        <div class="cover-show flex-layout">
            <div v-if="cover !== ''" class="show-inner csp" @click="deleteCover">
                <el-tooltip class="item" effect="dark" content="点击图片取消上传" placement="top">
                    <img :src="cover" alt="封面图片" />
                </el-tooltip>
            </div>
            <div class="cover_up csp" @click="openDrawer(true)">
                <img class="cover_image" src="@/assets/upload.png" alt="">
                <div class="cover_title">点击上传封面</div>
            </div>
            <div class="tips">
                大小:2M以内
                <br />
                格式:jpg、png 尺寸:277*169
            </div>
        </div>
        <!-- 本地断点续传 -->
        <el-drawer v-if="!BreakPointUpload" title="" :visible.sync="isOpenDrawer" @opened="openDialogAuto"
            :before-close="handleClose">
            <upimg ref="upimg" v-if="isOpenDrawer" :upimgload="true" @closeMain="closeMain"></upimg>
        </el-drawer>
        <!-- 阿里云上传 -->
        <el-drawer v-if="BreakPointUpload" title="" :visible.sync="isOpenDrawer" :before-close="handleClose" :append-to-body="true">
            <ossUploader v-if="isOpenDrawer" @closeMain="closeMain" @Lxupload="Lxupload"></ossUploader>
        </el-drawer>
    </div>
</template>

<script>
export default {
    props: {
        // 封面图
        xcover: {
            type: String
        }
    },
    data() {
        return {
            // isDisable:false,
            upimgload: false,
            isOpenDrawer: false, // 侧边栏是否呈开启状态
            flag: true, //是否是第一次进来
            cover: '', //封面地址
            md5: ''
        }
    },
    watch: {
        // xcover(newVal, oldVal) {
        //     console.log('xcover-----',newVal);
        //     if (!newVal) return;
        //     this.cover = this.downloadURL + newVal;
        //     // console.log(this.cover);
        //     this.md5 = newVal;
        // }
        xcover:{
            handler(val,oldVal){
                console.log('xcover-----',val)
                if(val){
                    this.cover = this.downloadURL + val;
                    this.md5 = val;
                }else{
                    this.cover = '';
                    this.md5 = ''
                }
            },
            immediate : true,
        }
    },
    computed: {},
    methods: {
        // 关闭右侧上传抽屉提示弹窗
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        // 删除文件
        deleteCover() {
            this.cover = "";
            this.$emit('uploadCover', {
                cover: "",
            });
        },
        // 打开右侧上传栏
        openDrawer(flag) {
            this.isOpenDrawer = flag;
            // console.log(this.myBrowser())
            this.upimgload = this.myBrowser() == 'Chrome' ? false : true;
        },
        // 自动打开浏览器弹窗
        openDialogAuto() {
            this.$refs.upimg.$el.querySelector("input").click();
        },
        // 文件上传成功后的回调
        closeMain(val) {
            this.cover = this.downloadURL + val.filemd5;
            console.log(this.cover);
            this.md5 = val.filemd5
            this.handleCoverChange();
        },
        // 文件预览及下载
        openFile(item) {
            window.open(item.filemd5)
        },
        handleCoverChange() {
            this.$emit('uploadCover', {
                cover: this.cover,
                md5: this.md5
            });
        },
        Lxupload(file){
            this.$emit('lxupload',file)
        }
    },
    activated() {
        if (this.xcover) {
            console.log('xxxxxx',this.xcover)
            this.cover = this.downloadURL + this.xcover;
            this.md5 = this.xcover;
        }

    },
    deactivated() {
        this.cover = '';
        this.md5 = '';
    },
}
</script>

<style lang="less" scoped>
.upload-cover {
    .cover-show {
        .show-inner {
            display: inline-block;
            width: 115px;
            height: 115px;
            margin-right: 10px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .cover_up {
        border: 1px dashed #888;
        border-radius: 10px;
        padding: 15px 20px;
        text-align: center;

        .cover_image {
            width: 50px;
            height: 40px;
        }

        .cover_title {
            line-height: 30px;
            color: #888;
            font-size: 12px;
        }
    }

    .tips {
        margin: 0 20px;
    }
}
</style>